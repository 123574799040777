<template>
	<br-generic-list-base :derived-component="_self">
		<template #item.pk="{ rowInfo, colInfo, modelField, finalContent }">
			<span>{{ rowInfo.model.pk }}</span>
		</template>
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList, B_REST_Vuetify_Prompt } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "clientSessionContractList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "ClientSessionContract",
			fromLoader: {
				apiBaseUrl: "/clientSessionContracts",
			},
			cols: {
				pk: {
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				status: {
					fieldNamePaths: "status",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				services_dt_from: {
					fieldNamePaths: "services_dt_from",
					toCellContent(col,model,defaultContent) { return defaultContent.substr(0,10); },
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				services_dt_to: {
					fieldNamePaths: "services_dt_to",
					toCellContent(col,model,defaultContent) { return defaultContent.substr(0,10); },
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				session: {
					fieldNamePaths: "session.name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				franchisee: {
					fieldNamePaths: "franchisee.name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					generatePDF: {
						click: {
							async hook(action,model,isCtrlClickOrMiddleClick)
							{
								const isTerminated = model.select("status").val===this.$bREST.consts.clientSessionContract_status.TERMINATED_FOR_FULL_UNREG;
								let   which        = "normal"; //Either normal | termination
								
								if (isTerminated)
								{
									const COMMON_ACTIONS_CANCEL = B_REST_Vuetify_Prompt.Prompt.COMMON_ACTIONS_CANCEL;
									
									which = await this.$bREST.prompt_helper({
										locBasePath: "components.clientSessionContractList.rowActions.generatePDF.whichPrompt",
										actions:     `${COMMON_ACTIONS_CANCEL}:secondary|-|normal:blue|termination:warning`,
									});
									if (which===COMMON_ACTIONS_CANCEL) { return true; }
								}
								
								const request = new this.$bREST.GET_File("clientSessionContracts/{pkTag}/generatePDF", {pkTag:model.pk});
								request.qsa_add("which", which);
								return this.$bREST.call_download(request);
							},
						},
						icon: "mdi-printer",
					},
					generatePDF_relatedContracts: {
						click: {
							async hook(action,model,isCtrlClickOrMiddleClick)
							{
								const request = new this.$bREST.GET_File("clientSessionContracts/{pkTag}/generatePDF_relatedContracts", {pkTag:model.pk});
								return this.$bREST.call_download_inlineNewWindow(request, this.$bREST.businessConfig.businessName_wEnv);
							},
						},
						icon: "mdi-view-column",
					},
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>