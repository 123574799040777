
//WARNING: Most of things in B_REST_Vuetify_GenericList_Col are ignored if we define <template #data-table-area> in our list implementation (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)

import { B_REST_Utils, B_REST_Model, B_REST_FieldDescriptors } from "../../../../../classes";
import B_REST_VueApp_base                                      from "../../../B_REST_VueApp_base.js";




export default class B_REST_Vuetify_GenericList_Col
{
	static get ALIGN_LEFT()   { return "start";  }
	static get ALIGN_CENTER() { return "center"; }
	static get ALIGN_RIGHT()  { return "end";    }
	static _ALIGNS = [
		B_REST_Vuetify_GenericList_Col.ALIGN_LEFT,
		B_REST_Vuetify_GenericList_Col.ALIGN_CENTER,
		B_REST_Vuetify_GenericList_Col.ALIGN_RIGHT,
	];
	static get DIVIDER_CLASS_NAME() { return "v-data-table__divider"; }
	
	_listComponent                 = null;   //BrGenericListBase der this belongs to
	_name                          = null;   //Unique name of the col in the table. In most cases will match _fieldNamePaths (when we only need a single field). Specify multiple, when we want to concat info. We can use advanced notation like "firstName|coords.address|a.b.<dbOnly>". WARNING: Mustn't have special chars
	_fieldNamePaths                = null;   //Either NULL, a single fieldNamePath, or piped for multiple fields. Will ask server to load all req fields. If we have exactly 1, then it'll fetch model.select(<fieldNamePaths>).val, and help get translation
	_fieldNamePaths_single_trimmed = null;   //For when we useToLabel=true, if fieldNamePaths was "<toLabel>", this will be "", and if we had "a.b.c.<toLabel>" this would be "a.b.c"
	_fieldDescriptor               = null;   //When _fieldNamePaths point on exactly 1 field, its B_REST_FieldDescriptor_x
	_useToLabel                    = false;  //If we request to use target model's toLabel() or some sub field's one, by setting fieldNamePaths to either "<toLabel>" or "a.b.c.<toLabel>"
	_toCellContent                 = null;   //Func as (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model,defaultContent), must ret what to put directly in cells. Otherwise calculated from _fieldDescriptor if available
	_click_isEnabled               = null;   //Either bool or func as (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model). WARNING: PICKER_ENABLE_x consts in BrGenericListBase overrides this
	_click_hook                    = null;   //Async func as (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model) if we want to listen to when we click on cols. Must ret bool
	_isEditable                    = false;  //Bool or func as (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model) that must ret bool. WARNING: PICKER_ENABLE_x consts in BrGenericListBase overrides this + user will have to handle save by himself
	_vBind                         = null;   //Props we want to pass to the underlying <br-field-db> or etc. Ex {placeholder:"bob",items:[],as:"timePicker"}. WARNING: Auto handled if we don't define a <template #item.xxx>, otherwise we must link it manually via <template #item.myCol="{ colInfo }">
	_vOn                           = null;   //Like vBind, but for events, like {change($event){}, input($event){}}, so we can be notified when stuff happens
	_extraData                     = null;   //Optional obj
	_label                         = null;   //Usually the shortLabel of a B_REST_FieldDescriptor_x
	_isVisible                     = null;   //Func as (<B_REST_Vuetify_GenericList_Col>col), ex to display only for some businessConfig. Both isVisible & style_fromBreakpoint must be true for a col to show up
	//CSS
	_style_fromBreakpoint = "xs";                                      //Show the col from X breakpoint and up. Vuetify breakpoint like xs|sm|md|lg|xl. Both isVisible & style_fromBreakpoint must be true for a col to show up
	_style_align          = B_REST_Vuetify_GenericList_Col.ALIGN_LEFT; //One of ALIGN_x. Applies to both header + content. For content, only auto used when we don't define <template #item.xxx>, and it being RO and not in edit mode
	_style_hasRightBorder = false;                                     //If we want to put vertical lines between cols
	_style_width          = null;                                      //Number or string
	_style_tdClass        = null;                                      //As (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model). Will go into a <td :class> NOTE: We have this.$bREST.classProp_addTag() helper
	_style_tdStyle        = null;                                      //As (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model). Will go into a <td :style> NOTE: We have this.$bREST.classProp_addTag() helper
							
	//WARNING: If we add stuff here, also add in constructor()
	
	
	/*
	Options as
		{
			fieldNamePaths: NULL | piped field name paths like "firstName|coords.address|a.b.<dbOnly>|coords.<toLabel>"
			toCellContent: (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model,defaultContent)
			isVisible: (<B_REST_Vuetify_GenericList_Col>col)
			click: {
				isEnabled: bool | (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model)
				hook:      async(<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model,isCtrlClickOrMiddleClick), //Must ret bool
			},
			isEditable: bool | (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model),
			style: {
				fromBreakpoint,
				align: ALIGN_x,
				hasRightBorder,
				width,
				tdClass: (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model), //Will go into a <td :class> NOTE: We have this.$bREST.classProp_addTag() helper
				tdStyle: (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model), //Will go into a <td :style> NOTE: We have this.$bREST.styleProp_addTag() helper
			},
			vBind:     {},
			vOn:       {},
			extraData: {},
		}
	
	Here are some isVisible() ex:
		...
		isVisible(col) { return this.$bREST.businessConfig.custom.coach_insuranceStuff_has; },
		...
	
	Here are some toCellContent() ex:
		...
		toCellContent(col,model,defaultContent)
		{
			return defaultContent?.substr(0,10) ?? null; //Ex if was a YmdHis and just wanted Ymd
			return model.select_nonEmptyValsConcatenated("coordinates(street_number|street_name|appt_number)");
			return model.select_firstNonEmptyVal("assignedTo.firstName+assignedTo.lastName|assignedTo.userName");
		},
		...
			WARNING: Most of things in B_REST_Vuetify_GenericList_Col are ignored if we define <template #data-table-area> in our list implementation (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)
	*/
	constructor(listComponent, colName, options)
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			fieldNamePaths: {accept:[String,null],      default:null},
			toCellContent:  {accept:[Function],         default:null},
			isVisible:      {accept:[Function],         default:null},
			click:          {accept:[Object],           default:null},
			isEditable:     {accept:[Boolean,Function], default:false},
			style:          {accept:[Object],           default:null},
			vBind:          {accept:[Object],           default:null},
			vOn:            {accept:[Object],           default:null},
			extraData:      {accept:[Object],           default:null},
		}, "Generic list col");
		
		this._listComponent  = listComponent;
		this._name           = colName;
		this._fieldNamePaths = options.fieldNamePaths;  //Can be NULL
		this._toCellContent  = options.toCellContent;
		this._isVisible      = options.isVisible;
		
		//Loc
		{
			//For loc, check if we can find a custom override implementing {shortLabel}. Otherwise, if we have a fieldDescriptor, try to use its one
			const customLocBasePath = `${this._listComponent.t_baseLocPath}.cols.${this._name}`; //Ex "components.contactPicker.cols.firstName"
			this._label =  B_REST_VueApp_base.instance.t_custom_orNULL(`${customLocBasePath}.${B_REST_VueApp_base.LOC_KEY_SHORT_LABEL}`) //Ex "components.contactPicker.cols.firstName.shortLabel"
			            || B_REST_VueApp_base.instance.t_custom_orNULL(`${customLocBasePath}.${B_REST_VueApp_base.LOC_KEY_LABEL}`);      //Ex "components.contactPicker.cols.firstName.label"
			
			//If fieldNamePaths points to a SINGLE field, now try to get its B_REST_FieldDescriptor instance
			if (this._fieldNamePaths)
			{
				const singleFieldMatch = this._fieldNamePaths.match(/^([\w.\[\]]*)(<toLabel>)?$/); //Allow "a_b.c[3]", "a.b.<toLabel>", but not "a|b", "a.<dbOnly>" or "a|<toLabel>"
				
				if (singleFieldMatch)
				{
					//Skim the prop to strip away any "<toLabel>" -> "" or "a.b.c.<toLabel>" -> "a.b.c"
					this._fieldNamePaths_single_trimmed = singleFieldMatch[1].replace(/\.$/,"");
					
					this._useToLabel = singleFieldMatch[2]!==undefined;
					
					//NOTE: Field name path could be "" with <toLabel>, leaving fieldDescriptor NULL
					this._fieldDescriptor = this._fieldNamePaths_single_trimmed ? this._listComponent.descriptor.allFields_find_byFieldNamePath(this._fieldNamePaths_single_trimmed) : null; //Might throw if field name path doesn't make sense
					
					//Do some validation
					if (this._useToLabel)
					{
						if (this._fieldDescriptor && !this.fieldDescriptor_isModel) { this._throwEx(`A <toLabel> col's fieldNamePaths expr must point to a B_REST_FieldDescriptor_ModelLookupRef or B_REST_FieldDescriptor_SubModel`); }
					}
					else if (!this.fieldDescriptor_isDB) { this._throwEx(`Col's fieldNamePaths expr must point to a B_REST_FieldDescriptor_DB`); }
					
					if (this._fieldDescriptor && this._label===null) { this._label=this._fieldDescriptor.shortLabel; }
				}
			}
			
			if (this._label===null)
			{
				this._label = `%${customLocBasePath}%`;
				B_REST_VueApp_base.instance.t_custom_warnNotFound(customLocBasePath);
			}
		}
		
		const clickOptions = options.click;
		if (clickOptions)
		{
			this._click_hook = clickOptions.hook || null;
			
			if (this._click_hook)
			{
				this._click_isEnabled = B_REST_Utils.object_hasPropName(clickOptions,"isEnabled") ? clickOptions.isEnabled : true;
			}
		}
		
		this._isEditable = options.isEditable;
		this._vBind      = options.vBind;
		this._vOn        = options.vOn;
		this._extraData  = options.extraData;
		
		if (options.style)
		{
			const styleOptions = B_REST_Utils.object_hasValidStruct_assert(options.style, {
				fromBreakpoint: {accept:[String],   default:"xs"},
				align:          {accept:[String],   default:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT},
				hasRightBorder: {accept:[Boolean],  default:false},
				width:          {accept:[Number],   default:null},
				tdClass:        {accept:[Function], default:null},
				tdStyle:        {accept:[Function], default:null},
			}, "Generic list col style");
			
			if (styleOptions.align && !B_REST_Vuetify_GenericList_Col._ALIGNS.includes(styleOptions.align)) { this._throwEx(`Expected align to be one of ALIGN_x. Got ${styleOptions.align}`); }
			
			this._style_fromBreakpoint = styleOptions.fromBreakpoint;
			this._style_align          = styleOptions.align;
			this._style_hasRightBorder = styleOptions.hasRightBorder;
			this._style_width          = styleOptions.width;
			this._style_tdClass        = styleOptions.tdClass;
			this._style_tdStyle        = styleOptions.tdStyle;
		}
	}
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg, details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`, details); }
	
	
	get listComponent() { return this._listComponent; }
	
	
	get name()                    { return this._name;                                                                           }
	get label()                   { return this._label;                                                                          }
	get debugName()               { return `B_REST_Vuetify_GenericList_Col<${this._name}@${this._listComponent.componentName}>`; }
	get vDataTableSlotName()      { return `item.${this._name}`;                                                                 } //To do something like <template #item.xxx>
	get fieldNamePaths()          { return this._fieldNamePaths;                                                                 }
	get fieldDescriptor()         { return this._fieldDescriptor;                                                                }
	get fieldDescriptor_isDB()    { return this._fieldDescriptor instanceof B_REST_FieldDescriptors.DB;                          } //Means we have only 1 field and it's a DB one
	get fieldDescriptor_isModel() { return this._fieldDescriptor instanceof B_REST_FieldDescriptors.WithFuncs_WithModels_base;   } //Means we have only 1 field and it's a lookup / sub model because we wanted to do a <toLabel>
	get useToLabel()              { return this._useToLabel;                                                                     }
	get useToLabel_main()         { return this._useToLabel && this._fieldDescriptor===null;                                     }
	get useToLabel_nested()       { return this._useToLabel && this._fieldDescriptor!==null;                                     }
	get toCellContent()           { return this._toCellContent;                                                                  }
	get isVisible()               { return this._isVisible;                                                                      }
	get style_fromBreakpoint()    { return this._style_fromBreakpoint;                                                           }
	get style_align()             { return this._style_align;                                                                    }
	get style_hasRightBorder()    { return this._style_hasRightBorder;                                                           }
	get style_width()             { return this._style_width;                                                                    }
	get vBind()                   { return this._vBind;                                                                          }
	get vOn()                     { return this._vOn;                                                                            }
	get extraData()               { return this._extraData;                                                                      }
	get cssClassBase()            { return `${this._listComponent.cssClassBase}--${this._name}`;                                 } //Ex "generic-list--brandTest--firstName"
	get cssClassBase_header()     { return `${this.cssClassBase}--header`;                                                       } //Ex "generic-list--brandTest--firstName--header"
	get cssClassBase_body()       { return `${this.cssClassBase}--body`;                                                         } //Ex "generic-list--brandTest--firstName--body"
	
	
	//Props we allow changing later
	set extraData(val) { this._extraData=val; }
	set vBind(val)     { this._vBind    =val; }
	set vOn(val)       { this._vOn      =val; }
	
	/*
	Only rets non NULL model field if either:
		-We have no field name path
		-Pointing to a single B_REST_FieldDescriptor_DB field (so no "a|b|c")
		-Pointing to a sub model / lookup where link is NULL, so we can't get to the "next" model or the last one in the chain
		-A <toLabel> for the main model ("<toLabel>" -> useToLabel_main()) instead of for a sub model ("a.b.c.<toLabel>" -> useToLabel_nested())
	*/
	getModelField(model) { return this._fieldDescriptor ? model.select(this._fieldNamePaths_single_trimmed||this._fieldNamePaths,/*singleSubModelFieldsRetModel*/false,/*throwIfCantNest*/false) : null; }
	
	
	
	isEditable(model) { return this._isStatusable(model,"_isEditable"); }
		_isStatusable(model, varName)
		{
			if (!this[varName])       { return false; }
			if (this[varName]===true) { return true;  }
			
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
			
			try       { return this[varName].call(this._listComponent,this,model);                   }
			catch (e) { B_REST_Utils.throwEx(`${varName} hook failed, for ${this.debugName}: ${e}`); }
			return false;
		}
	click_isEnabled(model) { return this._isStatusable(model,"_click_isEnabled"); }
	//Must ret if hook went well
	async click_hook(model, isCtrlClickOrMiddleClick)
	{
		if (!this._click_hook) { return false; }
		
		B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
		
		try       { return await this._click_hook.call(this._listComponent,this,model,isCtrlClickOrMiddleClick); }
		catch (e) { B_REST_Utils.throwEx(`click_hook failed, for ${this.debugName}: ${e}`);                      }
		return false;
	}
	
	style_tdClass(model)
	{
		let tdClass = this._style_tdClass ? this._style_tdClass.call(this._listComponent,this,model) : undefined;
		
		tdClass = B_REST_VueApp_base.instance.classProp_addTag(tdClass, this.cssClassBase_body); //Ex "generic-list--brandTest--firstName--body"
		tdClass = B_REST_VueApp_base.instance.classProp_addTag(tdClass, `text-${this._style_align}`); //Ex "text-start"
		if (this._style_hasRightBorder) { tdClass=B_REST_VueApp_base.instance.classProp_addTag(tdClass, B_REST_Vuetify_GenericList_Col.DIVIDER_CLASS_NAME); }
		
		return tdClass;
	}
	style_tdStyle(model)
	{
		if (!this._style_tdStyle) { return undefined; }
		
		B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
		return this._style_tdStyle.call(this._listComponent,this,model);
	}
	
	
	/*
	Outputs according to https://vuetifyjs.com/en/api/v-data-table/#props-headers
	Check BrGenericListBase.vue::toVDataTableHeaderDef() docs for more info
	*/
	toVDataTableHeaderDef(isFirstCol)
	{
		const tmp_isSingleFieldNamePathAndInDirectModel = this._fieldNamePaths_single_trimmed && this._fieldNamePaths_single_trimmed.indexOf(".")===-1; //NOTE: Actually dude could do "a(b)" and we'd be screwed, but edge case!
			//NOTE: For now, it's not possible to sort on sub model fields. Check server's Descriptor_base::_assert_validOptions() & Descriptor_base::_load_list_queryBuilder_prepare()
		
		return {
			value:      this._name,
			text:       this.label,
			align:      this._style_align,
			sortable:   this._fieldDescriptor && (!this._listComponent.useForLoading||this.fieldDescriptor_isDB) && tmp_isSingleFieldNamePathAndInDirectModel, //We can server sort on any DB field. If static list, then we should always be able to sort (w/o calling server), as long as it's not a custom component that has no fieldNamePaths
			filterable: isFirstCol, //Check in BrGenericListBase.vue::toVDataTableHeaderDefs() docs for why we do this
			groupable:  false,
			divider:    this._style_hasRightBorder,
			class:      this.cssClassBase_header,
			cellClass:  null, //We'll handle it ourselves
			width:      this._style_width,
			filter:     null,
			sort:       null,
			extraData:  {self:this},
		};
	}
};
