<template>
	
	<div :id="id" class="br-field-db" :class="final_class_theme" :data-debug-field-name-path="final_debugFieldNamePath" @[final_tooltip?`mouseover`:null]="ifTooltip_show=true" @[final_tooltip?`mouseleave`:null]="ifTooltip_show=false" @click="$emit('click')"> <!-- Req wrapper for tooltips -->
		
		<!-- For radio group, we're stuck because it doesn't have a label prop, so we need to make a fork -->
		<div v-if="final_componentName==='v-radio-group'" class="br-field-db--inner-component" :class="final_class_isDirty" style="position:relative;">
			
			<label v-if="final_label!==null" class="br-field-db--inner-component--radio-group--label v-label" :class="final_class_isUppercased">
				<v-icon v-if="!final_field_isMutable" class="br-field-db--inner-component--label-lock-icon mr-1" :dark="final_dark" :light="final_light" small>mdi-lock</v-icon>{{ final_label }}
			</label>
			<v-radio-group :value="final_field_value"
			               :error-messages="final_errorMsgs"
						   :dark="final_dark" :light="final_light"
			               :class="`${final_class_isDirty}`"
			               class="br-field-db--inner-component--radio-group--radio"
			               :row="final_ifRadioGroup_isHorizontal"
			               :readonly="final_readonly"
			               :disabled="final_disabled"
			               v-bind="brFieldDbAttrs"
				           @[final_field?`change`:null]="on_change"
			>
    
								<!-- WARNING: Don't do v-on="$listeners", or it will hide our own $emit() --->
								<!-- README STRANGE @[cond?`eventName`:null]="eventHandler" syntax: https://stackoverflow.com/questions/48042274/conditional-event-binding-vuejs -->
				<v-radio v-for="(loop_item,loop_item_idx) in final_items_usable" :key="loop_item_idx" :value="loop_item.key" :label="loop_item.label" :class="`${final_class_isUppercased}`" />
			</v-radio-group>
		</div>
		
		<component v-else
		           :is="final_componentName"
		           :value="final_field_value"
		           :persistent-placeholder="final_persistentPlaceholder"
		           :placeholder="final_placeholder"
		           :prefix="final_prefix"
		           :suffix="final_suffix"
		           :clearable="final_clearable"
		           :error-messages="final_errorMsgs"
		           :loading="final_loading"
		           class="br-field-db--inner-component"
		           :class="`${final_class_isDirty} ${final_class_usesPicker} ${final_class_isLabelAbove} ${final_class_isRounded} ${final_class_isUppercased}`"
				   :dark="final_dark" :light="final_light"
		           
		           :min="final_min"
		           :max="final_max"
		           :counter="final_counter"
		           :step="final_step"
		           :readonly="final_readonly"
		           :disabled="final_disabled"
				   :hide-spin-buttons="!showNumberUpDownArrows"
		           
		           :items="ifAutocomplete_typedText_isFilled ? ifAutocomplete_filterAndSort() : final_items"
		           item-value="key"
		           item-text="label"
		           :multiple="final_multiple"
		           :chips="final_chips"
		           :deletable-chips="final_chips && final_clearable"
				   :filter="ifAutocomplete_filterFunc"
				   :search-input.sync="ifAutocomplete_typedText"
		           
		           :type="final_ifInputOrDatePicker_type"
		           :true-value="trueValue"
		           :false-value="falseValue"
		           :input-value="final_ifSwitch_inputValue"
		           :rows="final_ifTextarea_lineCount"
		           :row="final_ifRadioGroup_isHorizontal"
		           :tick-labels="final_ifSlider_tickLabels"
		           :ticks="final_ifSlider_ticks"
		           :tick-size="final_ifSlider_tickSize"
		           :thumb-label="final_ifSlider_thumbLabel"
		           :use-seconds="final_ifTimePicker_useSeconds"
				   auto-select-first
				   
		           v-bind="brFieldDbAttrs"
		           @[final_field&&!lazy?`input`:null]="on_input"
		           @[final_field?`change`:null]="on_change"
		           @[final_field?`click:clear`:null]="on_clear_click"
				   @focus="ifAutocomplete_isTypingHack=false; $emit('focus')"
				   @blur=" ifAutocomplete_isTypingHack=false; $emit('blur' )"
				   @click="ifAutocomplete_isTypingHack=false;"
		>
						<!-- WARNING: Don't do v-on="$listeners", or it will hide our own $emit() --->
						<!-- README STRANGE @[cond?`eventName`:null]="eventHandler" syntax: https://stackoverflow.com/questions/48042274/conditional-event-binding-vuejs -->
				
			<!-- Label slot that could have a 🔓 -->
				<template v-if="final_label!==null" #label>
					<v-icon v-if="!final_field_isMutable" class="br-field-db--inner-component--label-lock-icon mr-1">mdi-lock</v-icon>{{ final_label }}
				</template>
			
			<!-- Prepend & append slots -->
				<template v-if="final_slotConfig_prepend" #prepend>
					<slot v-if="final_slotConfig_prepend.useSlot" name="prepend" />
					<v-btn v-else-if="final_slotConfig_prepend.click" icon @click.stop.prevent="final_slotConfig_prepend.click()" small>
						<v-icon :color="final_slotConfig_prepend.color" v-text="final_slotConfig_prepend.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_prepend.color" v-text="final_slotConfig_prepend.icon" />
				</template>
				
				<template v-if="final_slotConfig_prependInner" #prepend-inner> 
					<slot v-if="final_slotConfig_prependInner.useSlot" name="prepend-inner" />
					<v-btn v-else-if="final_slotConfig_prependInner.click" icon @click.stop.prevent="final_slotConfig_prependInner.click()" small>
						<v-icon :color="final_slotConfig_prependInner.color" v-text="final_slotConfig_prependInner.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_prependInner.color" v-text="final_slotConfig_prependInner.icon" />
				</template>
				
				<template v-if="final_slotConfig_append" #append> 
					<slot v-if="final_slotConfig_append.useSlot" name="append" />
					<v-btn v-else-if="final_slotConfig_append.click" icon @click.stop.prevent="final_slotConfig_append.click()" small>
						<v-icon :color="final_slotConfig_append.color" v-text="final_slotConfig_append.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_append.color" v-text="final_slotConfig_append.icon" />
				</template>
				
				<template v-if="final_slotConfig_appendOuter" #append-outer>
					<slot v-if="final_slotConfig_appendOuter.useSlot" name="append-outer" />
					<v-btn v-else-if="final_slotConfig_appendOuter.click" icon @click.stop.prevent="final_slotConfig_appendOuter.click()" small>
						<v-icon :color="final_slotConfig_appendOuter.color" v-text="final_slotConfig_appendOuter.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_appendOuter.color" v-text="final_slotConfig_appendOuter.icon" />
				</template>
			
			<!-- Progress bar (for pwd) / loader -->
				<template v-if="!!$slots['progress'] || final_ifPwd_showProgress" #progress>
					<slot              v-if="!!$slots['progress']" name="progress" />
					<v-progress-linear v-else-if="final_ifPwd_showProgress" :color="ifPwd.strengthColor" :value="ifPwd.strengthValue" absolute />
				</template>
			
			<!-- NOTE: For slots like "message" that pass down data, we'll have to use $scopedSlots['message'] instead of $slots['message'], and transfer with <slot name="message" v-bind="stuff" /> -->
		</component>
		
		<!-- If we need a tooltip -->
		<div v-if="final_tooltip" class="br-field-db-tooltip--positionner-1">
			<div class="br-field-db-tooltip--positionner-2">
				<div class="br-field-db-tooltip--positionner-3">
					<v-scale-transition> <div v-show="ifTooltip_show" class="br-field-db-tooltip--style" v-text="final_tooltip" /> </v-scale-transition>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	
	import { B_REST_FieldDescriptors, B_REST_Utils, B_REST_Model, B_REST_ModelFields, B_REST_ModelList } from "../../../../classes";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	import B_REST_Vuetify_RightDrawer    from "../rightDrawer/B_REST_Vuetify_RightDrawer.js";
	const FieldDescriptor_DB   = B_REST_FieldDescriptors.DB;
	const B_REST_ModelField_DB = B_REST_ModelFields.DB;
	
	import { VTextField, VTextarea, VAutocomplete, VSelect, VRadioGroup, VSlider, VSwitch, VCheckbox, VDatePicker, VTimePicker, VColorPicker } from "vuetify/lib";
	
	
	const SLIDER_TICK_SIZE = 4;
	
	const PWD_STRENGHT_COLORS = ["error","error", "warning", "success","success"];
	
	export const AS_AUTO         = "auto";
	export const AS_INPUT        = "input"; //Meaning <v-text-field>
	export const AS_TEXTAREA     = "textarea";
	export const AS_AUTOCOMPLETE = "autocomplete";
	export const AS_SELECT       = "select";
	export const AS_RADIO_GROUP  = "radioGroup";
	export const AS_SLIDER       = "slider";
	export const AS_SWITCH       = "switch";
	export const AS_CHECKBOX     = "checkbox";
	export const AS_DATE_PICKER  = "datePicker";  //WARNING: Not as popup + check notes in final_componentName()
	export const AS_MONTH_PICKER = "monthPicker"; //WARNING: Not as popup + check notes in final_componentName()
	export const AS_TIME_PICKER  = "timePicker";  //WARNING: Not as popup + check notes in final_componentName()
	export const AS_COLOR_PICKER = "colorPicker"; //WARNING: Not as popup + check notes in final_componentName()
	export const AS_X_CONSTS = [
		AS_AUTO,
		AS_INPUT, //Meaning <v-text-field>
		AS_TEXTAREA,
		AS_AUTOCOMPLETE,
		AS_SELECT,
		AS_RADIO_GROUP,
		AS_SLIDER,
		AS_SWITCH,
		AS_CHECKBOX,
		AS_DATE_PICKER,
		AS_MONTH_PICKER,
		AS_TIME_PICKER,
		AS_COLOR_PICKER,
	];
	
	
	const AS_X_COMPONENT_MAP = {};
		//Doesn't include AS_AUTO
		AS_X_COMPONENT_MAP[AS_INPUT]        = "v-text-field";
		AS_X_COMPONENT_MAP[AS_TEXTAREA]     = "v-textarea";
		AS_X_COMPONENT_MAP[AS_AUTOCOMPLETE] = "v-autocomplete";
		AS_X_COMPONENT_MAP[AS_SELECT]       = "v-select";
		AS_X_COMPONENT_MAP[AS_RADIO_GROUP]  = "v-radio-group";
		AS_X_COMPONENT_MAP[AS_SLIDER]       = "v-slider";
		AS_X_COMPONENT_MAP[AS_SWITCH]       = "v-switch";
		AS_X_COMPONENT_MAP[AS_CHECKBOX]     = "v-checkbox";
		AS_X_COMPONENT_MAP[AS_DATE_PICKER]  = "v-date-picker";
		AS_X_COMPONENT_MAP[AS_MONTH_PICKER] = "v-date-picker";
		AS_X_COMPONENT_MAP[AS_TIME_PICKER]  = "v-time-picker";
		AS_X_COMPONENT_MAP[AS_COLOR_PICKER] = "v-color-picker";
	
	const COMPONENTS_NEEDING_ITEMS = [
		//NOTE: To make code easier, provide both prog friendly names + actual component names
		AS_AUTOCOMPLETE, AS_X_COMPONENT_MAP[AS_AUTOCOMPLETE],
		AS_SELECT,       AS_X_COMPONENT_MAP[AS_SELECT],
		AS_RADIO_GROUP,  AS_X_COMPONENT_MAP[AS_RADIO_GROUP],
		AS_SLIDER,       AS_X_COMPONENT_MAP[AS_SLIDER],
	];
	
	export const COMPONENTS_MULTIPLEABLE = [
		//NOTE: To make code easier, provide both prog friendly names + actual component names
		AS_AUTO,
		AS_AUTOCOMPLETE, AS_X_COMPONENT_MAP[AS_AUTOCOMPLETE],
		AS_SELECT,       AS_X_COMPONENT_MAP[AS_SELECT],
	 // AS_CHECKBOX,     AS_X_COMPONENT_MAP[AS_CHECKBOX], //Check todo below
	 // Also, if it's being used as a picker, we'll be using a <v-text-field>, but we won't need to validate that case
	];
	
	const PICKER_COMPONENT_SINGLE   = "v-text-field";
	const PICKER_COMPONENT_MULTIPLE = "v-select";
		B_REST_Utils.console_todo([
			`Fix prob w textarea having strange border around the field`,
			`To support multiple checkboxes, we'd need multiple-array & multiple-obj, so we either select :items like ["a","c"] vs {a:true, b:false, c:true}`,
			`Allow using a <br-field-db multiple> to select contacts, that'd be lookups to contact_fk in a sub model list like Model_Client{pk,clientcontacts} with Model_ClientContact{pk,client_fk,contact_fk}`,
			`Allow not passing a model & fieldNamePath, where we'd just use a v-model, and inside the component create fake standalone B_REST_FieldDescriptor_DB & B_REST_ModelField_DB. Would be useful for uniformizing look of other controls not related to models and maybe needing a list of items anyways`,
		]);
	const PICKER_FAKE_LOADING_LABEL = "..."; //For _picker_multiple_fakeItems_recalc()
	
	export const PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT = 5;
	
	const SLIDER_NUMERIC_ALWAYS_SHOW_THUMB_LABEL = true; //true:only while dragging, "always":even when not dragging, but might hide elements in the UI above a bit, so true seems better
	const SLIDER_MAX_SHOWN_TICK_COUNT            = 8;    //Actually it should maybe depend on an arr.join().length to simulate them being too close to each other, but it's meaningless if we don't know the input's width
	
	const RADIO_GROUP_ORIENTATION_AUTO       = "auto"; //Vertical in mobile and horizontal in desktop
	const RADIO_GROUP_ORIENTATION_HORIZONTAL = "horizontal"
	const RADIO_GROUP_ORIENTATION_VERTICAL   = "vertical";
	const RADIO_GROUP_ALL_CONSTS = [
		RADIO_GROUP_ORIENTATION_AUTO,
		RADIO_GROUP_ORIENTATION_HORIZONTAL,
		RADIO_GROUP_ORIENTATION_VERTICAL,
	];
	
	const PICKER_EVENT_PROMPT = "picker:prompt";  //When we open the picker
	const PICKER_EVENT_SELECT = "picker:select";  //When we select 1 or more models, and rets as single model or arr depending on if isMultiple
	const PICKER_EVENT_CANCEL = "picker:cancel";  //When we close the picker wo doing nothing
	
	const VUETIFY_BREAKPOINTS = ["xs","sm","md","lg","xl"];
	
	const DEFAULT_AS                           = AS_AUTO;
	const DEFAULT_LAZY                         = false;
	const DEFAULT_SHORT_LABEL_UNTIL_BREAKPOINT = "sm";
	const DEFAULT_RADIO_GROUP_ORIENTATION      = RADIO_GROUP_ORIENTATION_AUTO;
	
	const DEFAULT_SLOT_CONFIG_PICKER_BTN  = "append"; //Check _final_slotConfig_x()
	const DEFAULT_SLOT_PWD_EYE_REVEAL_BTN = "append"; //Check _final_slotConfig_x()
	
	const COMPONENT_NAME         = "BrFieldDb";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	
	
	
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			/*
			Required props; either field as B_REST_ModelField_DB alone, or a combination of model.select(<fieldNamePath>), where field is used as the fieldNamePath. Can be defined later
			Check _checkReinit_field() docs for more info
			*/
			field:                           {type:undefined,    required:false, default:null, validator(val){ return val===null || val instanceof B_REST_ModelField_DB || B_REST_Utils.string_is(val); }}, //Either NULL, an instance of B_REST_ModelField_DB, or a fieldNamePath for model.select(<fieldNamePath>)
			model:                           {type:B_REST_Model, required:false, default:null}, //Either NULL or a B_REST_Model instance, to get the final field via model.select(<fieldNamePath>)
			//For if we want to have choices
			items:                           {type:undefined,    required:false, default:null, validator:validator_items}, //Can be either an arr, ModelList, or a shared list tag (Check B_REST_VueApp_base::sharedLists_x()). For arr of objs, default is to use {key,label,flatSearch}, but prop names can be specified
			itemsKeyName:                    {type:String,       required:false, default:null},  //When not set, will default to "key".                                                    Check final_items() for more info
			itemsLabelName:                  {type:undefined,    required:false, default:null},  //To completely remove feature, set to false. When not set, will default to "label".      Check final_items() for more info
			itemsFlatSearchName:             {type:undefined,    required:false, default:null},  //To completely remove feature, set to false. When not set, will default to "flatSearch". Check final_items() for more info
			multiple:                        {type:Boolean,      required:false, default:null},  //Automatically true if TYPE_ARR. IMPORTANT: If we change the default val here, has impacts in B_REST_Vuetify_GenericList_Filter's MULTIPLE_DEFAULT & generator's Generator_Worker_Parser_Model::UI_LIST_FILTER_MULTIPLE_DEFAULT
			autocomplete_sort:               {type:Boolean,      required:false, default:true},  //Check ifAutocomplete_filterAndSort() docs for info
			autocomplete_acceptSimilarChars: {type:Boolean,      required:false, default:true},  //Check ifAutocomplete_filterAndSort() docs for info
			//Other stuff
			id:                              {type:String,       required:false, default:null},  //Intercept this, because ex if we want to use this to do Vuetify.goTo() and we're using a <v-slider>, it won't work if it ends up in its hidden input
			inputType:                       {type:String,       required:false, default:null},  //To override how final_ifInputOrDatePicker_type() works, say field is a date but we want to implement a date picker by making it a readonly field w no clickable y/m/d parts
			label:                           {type:String,       required:false, default:null},  //If we want to force label to something diff than B_REST_ModelField_DB's label
			shortLabel:                      {type:String,       required:false, default:null},  //Like for label, we also have a B_REST_ModelField_DB::shortLabel prop we can override. NOTE: No ref to it here; actually just FW via brFieldDbAttrs()
			shortLabelUntilBreakpoint:       {type:String,       required:false, default:DEFAULT_SHORT_LABEL_UNTIL_BREAKPOINT, validator:validator_shortLabelUntilBreakpoint}, //Decides until which Vuetify breakpoint ($bREST.uiBreakpoint.name) to use shortLabel instead of label (whether the B_REST_ModelField_DB one or overriden one)
			noLabel:                         {type:Boolean,      required:false, default:false}, //Sometimes, we don't want to see the auto label
			labelAbove:                      {type:Boolean,      required:false, default:false}, //An alias to Vuetify's persistent-placeholder prop, since we don't have a direct prop for that
			required:                        {type:Boolean,      required:false, default:false}, //To visually force the field to be marked as required, even though it might not be
			counter:                         {type:Boolean,      required:false, default:false}, //By default, we don't show chars counters in final_counter(), because it's annoying when we have many fields
			dense_notSupported:              {type:Boolean,      required:false, default:false}, //WARNING: Does nothing; not implemented yet. Did custom thing in CPA (check Step0NewReasonFranchiseeSchedule.vue's css) to try to make dense ones, so could try to do something similar, and try to prevent component from having negative margins
			disabled:                        {type:Boolean,      required:false, default:null},
			readonly:                        {type:Boolean,      required:false, default:null},
			clearable:                       {type:Boolean,      required:false, default:null}, //Leave NULL to let final_clearable() figure out, otherwise either force true/false
			placeholder:                     {type:String,       required:false, default:null},
			prefix:                          {type:String,       required:false, default:null}, //Similar to the placeholder, but pads a text to the left
			suffix:                          {type:String,       required:false, default:null}, //Similar to the placeholder, but pads a text to the right
			tooltip:                         {type:String,       required:false, default:null},
			lazy:                            {type:Boolean,      required:false, default:DEFAULT_LAZY}, //Whether we want input val to change "oninput" or "onchange", to reduce reactivity in input mode
			as:                              {type:String,       required:false, default:DEFAULT_AS, validator:validator_as},
			min:                             {type:undefined,    required:false, default:null, validator:validator_minMax}, //For numerical <v-text-field> & <v-slider> as Number, and for date/time as String
			step:                            {type:Number,       required:false, default:null}, //For numerical <v-text-field> & <v-slider> as Number, and for date/time as String
			max:                             {type:undefined,    required:false, default:null, validator:validator_minMax}, //For numerical <v-text-field> & <v-slider> as Number, and for date/time as String
			showNumberUpDownArrows:          {type:Boolean,      required:false, default:true}, //For numerical <v-text-field>
			pwdStrengthBar:                  {type:Boolean,      required:false, default:true}, //For pwd, do we show the strength progress bar ?
			textareaLineCount:               {type:Number,       required:false, default:5},
			radioGroupOrientation:           {type:String,       required:false, default:DEFAULT_RADIO_GROUP_ORIENTATION, validator:validator_radioGroupOrientation},
			trueValue:                       {type:undefined,    required:false, default:true},  //For <v-checkbox> & <v-switch>
			falseValue:                      {type:undefined,    required:false, default:false}, //For <v-checkbox> & <v-switch>
			forLang:                         {type:String,       required:false, default:null},  //Req for TYPE_MULTILINGUAL_STRING. Ex "fr". If we want to use the user's lang, pass $bREST.locale_lang
			//Picker related
			picker:                          {type:String,       required:false, default:null},  //If to be used as a model picker, must point on a valid B_REST_Vuetify_PickerDef name that we can refer to via B_REST_VueApp_base::pickers_prompt_x(). WARNING: If we rename this prop, has impacts in B_REST_Vuetify_GenericList_Filter::constructor()
			pickerOptions:                   {type:Object,       required:false, default:null},  //Check _picker_emit() docs. Ex {apiBaseUrl,apiBaseUrl_path_vars,reloader,routeInfo,onPrompt_setFilters,lookupModelName}, if will open a BrGenericListBase der. WARNING: Don't pass inline objs like :picker-options="{someKey:someVal}". Instead have a computed that would make the same obj and just do :picker-options="computedThing". It's the same prob as w BrGenericListBase::fromLoader
			//Icons & their colors. Rather than annoying prepend|prepend-inner|append|append-outer, just go 1,2,3,4, and also pipe optionnal color. Ex "mdi-check|success" or animated "mdi-loading mdi-spin|error"
			icon1:                           {type:String,       required:false, default:null},
			icon2:                           {type:String,       required:false, default:null},
			icon3:                           {type:String,       required:false, default:null},
			icon4:                           {type:String,       required:false, default:null},
			//Anything else that we try to pass, will fall inside v-bind="brFieldDbAttrs" (using $attrs)
		},
		data()
		{
			return {
				final_field:                    null,  //NULL, or actual B_REST_ModelField_base instance to use
				ifPwd:                          null,  //NULL, or as {revealed:false, strengthColor:null, strengthValue:null}
				ifTooltip_show:                 false,
				ifPicker_handle:                null,  //NULL or B_REST_Vuetify_PickerHandle instance
				ifPicker_single_label:          null,  //When we're using a picker in !multiple mode, we'll put the label of the model behind the FK we currently point to, inside the readonly <v-text-field>
				ifPicker_multiple_fakeItems:    null,  //When in multiple mode though, we'll need to create fake <v-select> items in order for these to appear
				ifPicker_ignoreFieldValWatch:   false, //For final_field_val_forWatch()
				ifAutocomplete_typedText:       null,  //For ifAutocomplete_filterAndSort()
				ifAutocomplete_isTypingHack:    null,  //For ifAutocomplete_filterAndSort(). IMPORTANT: Leave NULL at start. Triggered from @click, @focus, @blur, ifAutocomplete_typedText watch & _on_inputOrChange()
				final_class_theme:              null,  //Either "theme--light" or "theme--dark"
				final_dark:                     null,
				final_light:                    null,
			};
		},
		watch: {
			field()  { this._checkReinit_field();  }, //Async
			model()  { this._checkReinit_field();  }, //Async
			picker() { this._checkReinit_picker(); }, //Async. NOTE: We also have B_REST_VueApp_base::pickers_prompt_x() to open fully working standalone pickers that don't need a BrFieldDb instance
			/*
			Req, because ex if val is changed from the outside, picker info and such don't get updated
			The ifPicker_ignoreFieldValWatch is because without that, when we select a val, it'd do:
				_picker_changeVal() -> _picker_single_updateLabel() -> _picker_x_getModelLabel() -> watch -> _checkReinit_picker() -> _picker_single_updateLabel() -> _picker_x_getModelLabel(NULL)
				and we lose the label
			*/
			final_field_val_forWatch()
			{
				if (this.picker)
				{
					if (this.ifPicker_ignoreFieldValWatch) { return; }
					
					//WARNING: It's possible we should also call _picker_changeVal() for ifPicker_multiple_fakeItems...
					
					this._checkReinit_picker(); //async
				}
			},
			ifAutocomplete_typedText()
			{
				//NOTE: On boot, this gets fired by Vuetify right after mounted(), so we need to ignore the first watch
				if (this.ifAutocomplete_isTypingHack===null) { this.ifAutocomplete_isTypingHack=false; }
				else                                         { this.ifAutocomplete_isTypingHack=true;  }
			},
		},
		beforeMount() { this._checkReinit_field(); },
		mounted()
		{
			const theme = this.$bREST.dom_getClosestTheme_component(this);
			this.final_class_theme = `theme--${theme}`;
			this.final_dark        = theme==="dark";
			this.final_light       = theme==="light";
		},
		computed: {
			uid() { return this._uid; }, //All Vue component instances have a unique id
			/*
			Make an obj merging App global BrFieldDb settings (B_REST_App_base::brFieldDbAttrs) with props set on -this- instance ($options.propsData)
			Since this alone wouldn't include the default vals for all other props, then we must first include all props before doing the above
			In other words, first get all props (both set & default vals), then replace some w global settings, then for those that were set, override again,
			and also at the end add other passed stuff that don't correspond to props.
			Check B_REST_VueApp_base::brFieldDbAttrs docs
			*/
			brFieldDbAttrs()
			{
				//NOTE for when migrating to Vue 3: if logic starts to fuck because of events appearing here, check to define "emits: []", otherwise events could get added to $attrs. https://v3-migration.vuejs.org/breaking-changes/emits-option#migration-strategy
				const attrs = {...this.$props, ...this.$bREST.brFieldDbAttrs, ...this.$options.propsData, ...this.$attrs};
				delete attrs.label; //Avoid hell, because otherwise, even if final_label() rets NULL (ex because of noLabel), we'd get one anyways
				return attrs;
			},
			//Field & field descriptor related
			final_field_descriptor() { return this.final_field?.fieldDescriptor;                },
			final_field_name()       { return this.final_field_descriptor?.name || "[noname]";  },
			final_field_quotedName() { return `<br-field-db field="${this.final_field_name}">`; }, //Just for _throwField() and B_REST_Model::toLabel()
			final_field_isLookup()   { return this.final_field_descriptor?.lookup_is;           },
			final_field_isRequired()
			{
				const final_field_descriptor = this.final_field_descriptor;
				if (!final_field_descriptor) { return this.required; }
				return this.required || this.final_field_descriptor.isRequired || this.final_field?.forceIsRequired;
			},
			final_field_isNullable()
			{
				if (this.final_field_isRequired) { return false; }
				
				const final_field_descriptor = this.final_field_descriptor;
				if (!final_field_descriptor) { return false; }
				return final_field_descriptor.isNullable;
			},
			final_field_isMutable()
			{
				//NOTE: If it's a shared lookup, it's normal we can't edit
				
				if (!this.final_field || !this.final_field.isMutable) { return false; }
					//NOTE: We used to also check for this.final_field_descriptor.wCustomSetter, but it wasn't right; check B_REST_FieldDescriptor_base::wCustomSetter docs for why
				
				return !(this.final_field_descriptor.isPKField && this.final_field_descriptor.descriptor?.isAutoInc); //NOTE: If we use BrFieldDb w standalone fields, they don't have descriptors (thus we don't care about AUTO_INC)
			},
			//Gen stuff
			final_counter()               { return this.counter && !!(this.final_min||this.final_max);                                                              }, //Used for <v-text-field> & <v-textarea>
			final_readonly()              { return this.readonly || !this.final_field_isMutable || this.final_picker_asText;                                        }, //Can put focus, but can't edit. For pickers, we opt for this rather than disabled, otherwise btns aren't usable
			final_disabled()              { return this.disabled;                                                                                                   }, //Can't even focus, and turns grey
			final_picker_asText()         { return this.picker && !this.final_multiple;                                                                             },
			final_picker_asChips()        { return this.picker &&  this.final_multiple;                                                                             },
			final_picker_needs()          { return this.picker && !this.readonly && this.final_field_isMutable && !this.final_disabled;                             }, //WARNING: Don't use final_readonly(). Check final_clearable() + final_readonly() docs
			final_class_isDirty()         { return this.final_field?.userTouch_has ? "br-field-db--inner-component--is-dirty"       : "";                           },
			final_class_usesPicker()      { return this.final_picker_needs         ? "br-field-db--inner-component--uses-picker"    : "";                           },
			final_class_isLabelAbove()    { return this.brFieldDbAttrs.labelAbove  ? "br-field-db--inner-component--is-label-above" : "";                           },
			final_class_isRounded()       { return this.brFieldDbAttrs.rounded     ? "br-field-db--inner-component--is-rounded"     : "";                           },
			final_class_isUppercased()    { return this.brFieldDbAttrs.uppercased  ? "text-uppercase"                               : "";                           },
			final_tooltip()               { return this.tooltip;                                                                                                    }, //We could change later to put placeholder etc depending on final_componentName
			final_placeholder()           { return this.placeholder || this.final_field_descriptor?.placeholder || (this.final_persistentPlaceholder?"":undefined); }, //NOTE: If making standalone lookups and we see "0 and more" in placeholder, consider using B_REST_VueApp_CreateCoreMixin::coreMixin_createStandalone_lookup_x(). Can yield undefined
			final_persistentPlaceholder() { return this.brFieldDbAttrs.persistentPlaceholder ?? this.brFieldDbAttrs.labelAbove ?? false;                            }, //There's no prop to have label above in Vuetify, so we need a hack to make it possible
			final_prefix()                { return this.prefix;                                                                                                     },
			final_suffix()                { return this.suffix;                                                                                                     },
			final_clearable()
			{
				if (this.clearable===true || this.clearable===false) { return this.clearable; }
				
				const fieldDescriptor = this.final_field_descriptor;
				if (fieldDescriptor)
				{
					if (fieldDescriptor.isPKField || this.final_field_isRequired) { return false; } //NOTE: For PK fields, we should use them only when it's non AUTO_INC and prolly being a multi-field PK
				}
				if (this.final_disabled) { return false; }
				
				return !this.final_readonly || this.final_picker_needs; //NOTE: For final_picker_needs, we "fake" that it's readonly. Check final_readonly notes
			},
			/*
			Used for <v-text-field>, <v-textarea>, <v-slider>, <v-date-picker> & <v-time-picker>
			NOTE: If making standalone lookups and we see "0 and more" in placeholder, consider using B_REST_VueApp_CreateCoreMixin::coreMixin_createStandalone_lookup_x().
			*/
				final_min() { return this._final_minMax("min"); },
				final_max() { return this._final_minMax("max"); },
			/*
			Used for numerical <v-text-field> & <v-slider>, and for date/time. In ints, *tries* to disallow floats
			BUG:
				Even if we put a parseFloat() around this.step when defined, it'll complain that it expected a number,
				prolly because v-bind="brFieldDbAttrs" goes first before it can be evaluated. If we put a debugger here, it breaks before...
			*/
			final_step()
			{
				if (!this.final_field) { return null; }
				
				if (this.final_componentName==="v-slider" && this.final_items_usable.length>0)
				{
					if (this.step) { this._throwField(`Shouldn't specify step prop when we use <v-slider> with items / enum members`); }
					return 1;
				}
				
				if (this.step) { return parseFloat(this.step); } //Check note above
				
				switch (this.final_field_descriptor.type)
				{
					case FieldDescriptor_DB.TYPE_INT:     return 1;
					case FieldDescriptor_DB.TYPE_DECIMAL: return 1/Math.pow(10,this.final_field_descriptor.decimals);
					case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: return FieldDescriptor_DB.DT_CARE_ABOUT_SECONDS ? 1 : 60;
					//NOTE: No case for TYPE_D, as they don't display time
					case FieldDescriptor_DB.TYPE_T: return FieldDescriptor_DB.T_CARE_ABOUT_SECONDS  ? 1 : 60;
				}
				
				return null;
			},
			//Check watch w the same name for docs
			final_field_val_forWatch() { return this.final_field?.val ?? null; },
			//NOTE: If it's a lookup, we will NOT use the field's val, and instead show the label, but mods won't be possible
			final_field_value()
			{
				if (!this.final_field) { return null; }
				if (this.picker)       { return this.final_multiple ? this.final_field.val : this.ifPicker_single_label; }
				
				let val = null;
				
				if (this.final_field_descriptor.type_is_multilingualString)
				{
					if (!this.forLang) { this._throwField(`Prop forLang is req for TYPE_MULTILINGUAL_STRING`); }
					val = this.final_field.val_get_inXLang(this.forLang); //Could yield NULL
				}
				else { val=this.final_field.val; }
				
				//For <v-slider> on final_items (not number ranges), convert selected key to idx. Check notes inside the _final_minMax() method
				if (this.final_componentName==="v-slider" && this.final_items_usable.length>0)
				{
					if (val===null) { return 0; } //When null, we should always point on the 1st item
					
					const idx = this.final_items_usable.findIndex(loop_item => loop_item.key===val);
					if (idx===-1)
					{
						B_REST_Utils.console_warn(`${this.final_field_quotedName}: Got prob because we've previously selected key "${val}", but it's not in the known items. Fallbacking to "selecting first idx"`);
						return 0; //Don't throw now, otherwise the component won't appear at all
					}
					return idx;
				}
				
				if (val===null) { return null; }
				
				switch (this.final_field_descriptor.type)
				{
					case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: case FieldDescriptor_DB.TYPE_D: case FieldDescriptor_DB.TYPE_T: return this.final_field.dtVal_asHTML5InputVal;
					default: return val;
				}
			},
			//Only display them when we have touches
			final_errorMsgs()
			{
				//NOTE: We also have this.final_field.validation_isValid getter
				
				if (!this.final_field) { return null; }
				
				const errors = this.final_field.userTouch_has ? this.final_field.validation_getErrors(/*detailed*/false,/*onlyOne*/false,/*includeAsyncCustomErrors*/true) : null;
				if (errors && errors.length>0) { return errors; }
				
				//NOTE: This case is just for visuals and won't prevent any model to be saved / validation to fail
				if (this.required && !this.final_field.isNotEmpty_andValid) { return this.t_alt("errorMsgs.fakeRequired",{fieldName:this.final_label}); }
				
				return null;
			},
			//Especially for pwds strength progress bar, or simply for when we don't have the final_field, because at that time we won't even have a label, so let the user know something will happen soon
			final_loading()
			{
				if (B_REST_Utils.object_hasPropName(this.$attrs,"loading")) { return this.$attrs.loading; }
				
				if (!this.final_field) { return true; }
				
				return this.final_ifPwd_showProgress && this.ifPwd.strengthColor ? true : false; //Need this for pwd strength progress bar. Also use ternary to convert nulls to bool, otherwise we'll get an endless spinner
			},
			final_label()
			{
				const debugFieldNamePath = this.$bREST.debug_fieldNamePaths ? `[${this.final_debugFieldNamePath}]` : null;
				
				if (this.noLabel) { return debugFieldNamePath; }
				
				const labelFieldName = this.$bREST.uiBreakpoint_isXAndDown(this.shortLabelUntilBreakpoint) ? "shortLabel" : "label";
				
				if (this[labelFieldName]) { return debugFieldNamePath ? `${this[labelFieldName]} ${debugFieldNamePath}` : this[labelFieldName]; }
				if (!this.final_field)    { return debugFieldNamePath; }
				
				let label = this.final_field[labelFieldName];
				if (label===null) { return debugFieldNamePath; }
				
				//NOTE: We used to add a "🔓" here when !final_field_isMutable, for setOnce & readonly things, but now we do it directly in the label slot with mdi-lock
				
				if (this.forLang)                { label += ` (${this.forLang})`;     } //For TYPE_MULTILINGUAL_STRING
				if (debugFieldNamePath)          { label += ` ${debugFieldNamePath}`; }
				if (this.final_field_isRequired) { label += " *";                     }
				
				return label;
			},
				//Tries to ret a fieldNamePath for the field
				final_debugFieldNamePath()
				{
					if (this.field===null) { return "<unnamed>"; }
					if (this.field instanceof B_REST_ModelField_DB) { return this.field.debugFieldNamePath(); }
					return this.field; //If we get here, is a fieldNamePath and we don't have the model yet
				},
			final_slotConfig_prepend()      { return this._final_slotConfig_x("prepend",      "icon1"); },
			final_slotConfig_prependInner() { return this._final_slotConfig_x("prepend-inner","icon2"); },
			final_slotConfig_append()       { return this._final_slotConfig_x("append",       "icon3"); },
			final_slotConfig_appendOuter()  { return this._final_slotConfig_x("append-outer", "icon4"); },
			/*
			If it's for an FK bound on a lookup, then we'll always force to use a <v-text-field type="text">
			If we chose AS_AUTO:
				-If we have items / enum members / is a TYPE_ARR, will either choose <v-autocomplete> or <v-select>, depending on nb of items
				-If it's a TYPE_BOOL, will choose a <v-switch>
				-Otherwise all will fallback to <v-text-field>
			Otherwise, we'll blindly use the one specified:
				-Some funcs will throw errs depending on the type of component used
				-AS_DATE_PICKER shouldn't be used for a date+time field, so will throw with TYPE_DT or TYPE_X_STAMP
				-AS_MONTH_PICKER isn't yet implemented anywhere in frontend/backend, and we should prolly implement a TYPE_D_MONTH or TYPE_MONTH as a DB field, but require to use a VARCHAR
			About pickers (color, date, time, etc):
				-Decided they won't open in popups, since all of them have HTML5 equivalents via <input type="x" />
				-However, they don't show label nor validation err msgs; not sure if it's ok
			WARNING: If we want to support new one from Vuetify, will have to import it. Check "import { VTextField, ...} from vuetify/lib" at top of <script>
			*/
			final_componentName()
			{
				if (this.picker) { return this.final_multiple ? PICKER_COMPONENT_MULTIPLE : PICKER_COMPONENT_SINGLE; }
				
				let componentName = null;
				
				if (this.as===AS_AUTO)
				{
					//NOTE: We add the 2nd clause for enums, that have no "items" but still yield non-empty final_items
					if      (this.final_field_descriptor?.type_is_bool)      { componentName = this.final_field_isNullable                                                 ? "v-radio-group"  : "v-switch"; }
					else if (this.items || this.final_items_usable.length>0) { componentName = this.final_items_usable.length>=PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT ? "v-autocomplete" : "v-select"; } 
					else                                                     { componentName = "v-text-field"; }
				}
				else
				{
					if (this.as===AS_DATE_PICKER)
					{
						switch (this.final_field_descriptor?.type)
						{
							case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP:
								this._throwField(`Can only use field as datePicker with TYPE_D; not suitable for TYPE_DT or time stamps, for ex`);
							break;
							//Allow with TYPE_STRING, TYPE_D & TYPE_CUSTOM at least...
						}
					}
					else if (this.as===AS_MONTH_PICKER)
					{
						switch (this.final_field_descriptor?.type)
						{
							case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_D: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP:
								this._throwField(`Should only use monthPicker with TYPE_STRING, TYPE_CUSTOM etc. Also in server we should implement a TYPE_D_MONTH or something`);
							break;
							//Allow with TYPE_STRING & TYPE_CUSTOM at least...
						}
					}
					
					/*
					NOTE: If we want to use either of <v-autocomplete>, <v-select>, <v-radio-group>, <v-slider>, we should also define the items prop,
						but we won't throw even if we don't, in case we get them later
					*/
					componentName = AS_X_COMPONENT_MAP[this.as] || this._throwField(`No matching component when using as "${this.as}"`);
				}
				
				//NOTE: Notice we don't do this check when it has a picker (at the beginning of the func), because having a picker it should be possible
				if (this.final_multiple && !COMPONENTS_MULTIPLEABLE.includes(componentName))
				{
					//Just in case it's a TYPE_ARR, give a less wtf explanation about what happened here
					if (this.final_field_descriptor?.type_is_arr) { this._throwField(`TYPE_ARR fields always need :items for now`); } //(Has this check at 2 places in code) Until ex if one day we support an enum members prop for them
					
					this._throwField(`Can't end up using a <${componentName}> when we have the multiple attr on`);
				}
				
				return componentName;
			},
			/*
			For <v-autocomplete>, <v-select>, <v-radio-group>, <v-slider> (for slider will require being sent as final_ifSlider_tickLabels though)
			Yields an arr of either of {key,label,flatSearch,disabled [, model]}, {divider:true} or {header:<string>}
			NOTES ON DIVIDER & HEADER:
				Those which we set either divider / header props will NOT render an item, but just a separator that's either a line or grey text,
				so don't include other props for them
			Flat search is for ifAutocomplete_filterAndSort(), to do partial search like "Mtl|Montréal|Montreal"...
			The item prop may either contain an arr of such obj, or a ModelList instance.
				If arr:
					Can work w both primitives and objs, ex [123, 456, {key:789,label:"hey"}]
					If objs:
						Use the items-key-name prop to specify which prop to use for the key field, if it isn't called "key" in the obj.
						Same thing with items-label-name, if the label prop isn't called "label" in the obj.
						Same thing with items-flat-search-name, defaults to "flatSearch"
						Remember we can have props like {disabled, divider, header} as well
				If ModelList:
					Use the items-key-name prop to specify which field (can be a sub field in the model) to use for the key. If not set, will use the 1st PK field
					Same thing with the items-label-name prop, and if not specified, will use the Model_base::toLabel(final_field_quotedName) method of the model
					Same thing with the items-flat-search-name prop, and if not specified, will use the Model_base::toLabel(final_field_quotedName-flatSearch) method of the model
				If enum members:
					We allow defining tag via an arr, in case it's something recurring like yes/no
					If nothing is define, then we check the B_REST_FieldDescriptor_DB_EnumMember vals behind the B_REST_FieldDescriptor_DB
			Note that we expect data to change so the items should be reactive
			WARNINGS:
				-It's not possible to have a NULL key, otherwise we can't make the distinction between having nothing selected vs selecting an item
				-Avoid manually setting .val to a key that's currently disabled
				-To make things simpler, no matter the dynamic prop names we pass, they'll get converted to "key", "label" & "flatSearch"
			*/
			final_items()
			{
				if (this.ifPicker_multiple_fakeItems) { return this.ifPicker_multiple_fakeItems; }
				
				const final_items = [];
				
				const isEnabled_label      = this.itemsLabelName     !==false; //NOTE: null or string is OK
				const isEnabled_flatSearch = this.itemsFlatSearchName!==false; //NOTE: null or string is OK
				
				//When it's a shared list tag, it'll either become a B_REST_ModelList or arr
				const items = B_REST_Utils.string_is(this.items) ? this.$bREST.sharedLists_getSrc(this.items) : this.items;
				
				if (items instanceof B_REST_ModelList)
				{
					const modelList           = items;
					const ifUsePK_canUsePK    = this.itemsKeyName                     ? null : !modelList.descriptor.isMultiFieldPK;
					const ifUseToLabel_reason = this.itemsLabelName||!isEnabled_label ? null : this.final_field_quotedName; //Ex <br-field field="firstName">
					
					for (const loop_model of modelList.models)
					{
						let loop_label      = null;
						let loop_flatSearch = null;
						
						if (isEnabled_label) //NOTE: null or string is OK
						{
							loop_label = this.itemsLabelName ? loop_model.select(this.itemsLabelName).val : loop_model.toLabel(ifUseToLabel_reason);
							if (loop_label===undefined || loop_label===null) { loop_label=""; } //NOTE: We need the undefined checks, because if the toLabel funcs got intermediate custom funcs that don't ret anything, we'll get undefined
						}
						
						if (isEnabled_flatSearch && this.itemsFlatSearchName) //NOTE: null or string is OK
						{
							loop_flatSearch = loop_model.select(this.itemsFlatSearchName).val ?? null; //IMPORTANT: Must be done via server's GenUtils::string_flatSearchify()
						}
						
						final_items.push({
							key:            this.itemsKeyName ? loop_model.select(this.itemsKeyName).val : (ifUsePK_canUsePK&&loop_model.pk_isSet?loop_model.pk:null),
							label:          loop_label      ? loop_label.toString()      : null,
							flatSearchTags: loop_flatSearch ? loop_flatSearch.split("|") : null, //IMPORTANT: Must be a result of server's GenUtils::string_flatSearchify()
							disabled:       false,
							model:          loop_model,
							//NOTE: Don't add divider & header props
						});
					}
				}
				else if (B_REST_Utils.array_is(items))
				{
					const itemsKeyName        = this.itemsKeyName        || "key";
					const itemsLabelName      = this.itemsLabelName      || "label";
					const itemsFlatSearchName = this.itemsFlatSearchName || "flatSearch";
					
					for (const loop_item of items)
					{
						if (B_REST_Utils.object_is(loop_item))
						{
							//Is it a normal item or divider / header ?
							if (B_REST_Utils.object_hasPropName(loop_item,itemsKeyName))
							{
								const loop_key = loop_item[itemsKeyName];
								
								//Prevent hell
								if (loop_item.divider || loop_item.header) { this._throwField(`Can't put a divider or header directive on a normal item`,loop_item);              }
								if (loop_key===null)                       { this._throwField(`Can't have items with a NULL key val. Check method docs for more info`,loop_item); }
								
								let loop_label      = null;
								let loop_flatSearch = null;
								
								if (isEnabled_label)      { loop_label      = B_REST_Utils.object_hasPropName(loop_item,itemsLabelName)      ? loop_item[itemsLabelName]      : null; }
								if (isEnabled_flatSearch) { loop_flatSearch = B_REST_Utils.object_hasPropName(loop_item,itemsFlatSearchName) ? loop_item[itemsFlatSearchName] : null; }
								
								final_items.push({
									key:            loop_key,
									label:          loop_label      ? loop_label.toString()      : null,
									flatSearchTags: loop_flatSearch ? loop_flatSearch.split("|") : null, //IMPORTANT: Must be a result of B_REST_Utils::string_flatSearchify()
									disabled:       loop_item.disabled, //May yield undefined
									model:          null,
									//NOTE: Don't add divider & header props
								});
							}
							else if (loop_item.divider) { final_items.push({divider:true});            }
							else if (loop_item.header)  { final_items.push({header:loop_item.header}); }
							else { this._throwField(`Didn't know what to do with this :items="x"; maybe it's an obj w no {${itemsKeyName},${itemsLabelName}}`,loop_item); }
						}
						//Primitive vals, mostly if TYPE_ARR
						else
						{
							final_items.push({
								key:            loop_item,
								label:          loop_item, //For now, we'll just display the val as is, but if it's for a picker, maybe we'd want to fetch the labels another way (though in that case :items should be an arr of {key,label}
								flatSearchTags: null,
								disabled:       false,
								model:          null,
								//NOTE: Don't add divider & header props
							});
						}
					}
				}
				else if (this.final_field_descriptor?.enum_members) //IMPORTANT: Don't check by type_is_enum, because in multiple mode, we need it to be a type_is_json
				{
					/*
					To prevent trying to calc lots of translation for things that might not actually need to display items in the end
					and causing lots of warning in console, only add the enum members to the items, if we don't know what component we want,
					or if we know it and it can have some.
					IMPORTANT:
						Don't do the following, or we'd get an infinite loop:
							final_items_needs() { return COMPONENTS_NEEDING_ITEMS.includes(this.final_componentName); }
					*/
					
					if (this.as===AS_AUTO || COMPONENTS_NEEDING_ITEMS.includes(this.as))
					{
						for (const loop_enumMember of this.final_field_descriptor.enum_members)
						{
							final_items.push({
								key:        loop_enumMember.tag,
								label:      isEnabled_label ? loop_enumMember.label : null,
								flatSearchTags: null, //What about isEnabled_flatSearch ?
								disabled:   false,
								model:      null,
								//NOTE: Don't add divider & header props
							});
						}
					}
				}
				else if (this.final_field_descriptor?.type_is_bool)
				{
					if (this.final_field_isNullable) { final_items.push({key:null,label:this.final_field_descriptor.loc_bool_null, flatSearchTags:null,disabled:false,model:null}); }
					
					final_items.push({key:true,  label:this.final_field_descriptor.loc_bool_true,  flatSearchTags:null,disabled:false,model:null});
					final_items.push({key:false, label:this.final_field_descriptor.loc_bool_false, flatSearchTags:null,disabled:false,model:null});
				}
				
				return final_items; //Always ret an arr, otherwise if we figure out we have to use a <v-select>, <v-autocomplete> etc and we don't have the required items prop, Vuetify will throw
			},
			//Like final_items, but removing disabled stuff and UI dividers/headers. Note that it's used in the UI when AS_RADIO_GROUP, but for other components, :items doesn't use this
			final_items_usable() { return this.final_items.filter(loop_item => !loop_item.disabled&&!loop_item.divider&&!loop_item.header); },
			ifAutocomplete_typedText_isFilled() { return this.ifAutocomplete_isTypingHack && this.ifAutocomplete_typedText!==null && this.ifAutocomplete_typedText!==""; },
			/*
			When using items, rets actual obj of which we selected
			If src was a shared list, helps get back the model behind the selected FK, ex doing selectedItem.model. Also has selectedItem_model shortcut
			Use case:
				<br-field-db ref="someField" items="someSharedList" />
				methods: {
					selectedItem_getModel() { return this.$refs.someField.selectedItem_model; }
				}
			WARNING:
				Refs aren't available before mounted(), so might not fit w intended usage if used as computed, in data(), created() etc
				Refs are also not reactive, so putting "this.$refs.someField.selectedItem_model" into a computed could not be updated all the time - Vue says to use $emit instead
				In such case, should do something like:
					this.$bREST.sharedLists_getSrc("someSharedList").get_byPK(123)
			*/
			selectedItem()
			{
				const selectedKey = this.final_field_value;
				if (selectedKey===null) { return null; }
				
				for (const loop_item of this.final_items)
				{
					if (selectedKey===loop_item.key) { return loop_item; }
				}
				return null;
			},
			selectedItem_model()       { return this.selectedItem?.model ?? null; }, //Check selectedItem() docs
			final_multiple()           { return this.multiple||this.final_field_descriptor?.type_is_arr; }, //NOTE: For now, if it's a TYPE_ARR we'll never allow being !multiple, but maybe one day we'll want to force it to :multiple="false" ex because client wants to limit to 1 choice, but then it'd be better to change to TYPE_INT for ex
			final_chips()              { return this.final_multiple; }, //For now, we want this to be the default behavior for <v-select>, even when it's not for a picker / final_picker_asChips
			final_ifPwd_showProgress() { return this.ifPwd && this.final_field_descriptor?.pwd_evalStrength; },
			final_ifInputOrDatePicker_type()
			{
				if (this.inputType!==null) { return this.inputType; }
				
				if (!this.final_field) { return null; }
				
				if (this.final_componentName==="v-text-field")
				{
					if (this.picker)          { return "text"; }
					if (this.ifPwd?.revealed) { return "text"; }
					
					const noIdeaFallback = "text"; //We just don't know what to put for these yet
					switch (this.final_field_descriptor.type)
					{
						case FieldDescriptor_DB.TYPE_STRING:              return "text";
						case FieldDescriptor_DB.TYPE_INT:                 return "number";
						case FieldDescriptor_DB.TYPE_DECIMAL:             return "number";
						case FieldDescriptor_DB.TYPE_BOOL:                return noIdeaFallback;
						case FieldDescriptor_DB.TYPE_JSON:                return noIdeaFallback;
						case FieldDescriptor_DB.TYPE_DT:                  return "datetime-local";
						case FieldDescriptor_DB.TYPE_D:                   return "date";
						case FieldDescriptor_DB.TYPE_T:                   return "time";
						case FieldDescriptor_DB.TYPE_C_STAMP:             return "datetime-local";
						case FieldDescriptor_DB.TYPE_U_STAMP:             return "datetime-local";
						case FieldDescriptor_DB.TYPE_ENUM:                return noIdeaFallback;
						case FieldDescriptor_DB.TYPE_PHONE:               return "tel";
						case FieldDescriptor_DB.TYPE_EMAIL:               return "email";
						case FieldDescriptor_DB.TYPE_PWD:                 return "password";
						case FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH:    return "text";
						case FieldDescriptor_DB.TYPE_ARR:                 return noIdeaFallback;
						case FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING: return "text";
						case FieldDescriptor_DB.TYPE_CUSTOM:              return noIdeaFallback;
						default: this._throwField(`Unknown DB field type "${this.final_field_descriptor.type}"`);
					}
				}
				else if (this.final_componentName==="v-date-picker") { return this.as===AS_MONTH_PICKER ? "month" : "date"; }
				
				return null;
			},
			final_ifTextarea_lineCount() { return this.final_componentName==="v-textarea" ? this.textareaLineCount : null; },
			final_ifRadioGroup_isHorizontal()
			{
				if (this.final_componentName!=="v-radio-group") { return null; }
				
				switch (this.radioGroupOrientation)
				{
					case RADIO_GROUP_ORIENTATION_AUTO:       return !this.$bREST.uiBreakpoint.mobile; //NOTE: We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
					case RADIO_GROUP_ORIENTATION_HORIZONTAL: return true;
					case RADIO_GROUP_ORIENTATION_VERTICAL:   return false;
					default:                                 this._throwField(`Unknown radio group orientation "${this.radioGroupOrientation}"`);
				}
				
				return null;
			},
			//If we don't do this, when it's set to true and we first load the component, it'll appear unchecked...
			final_ifSwitch_inputValue() { return this.final_field_value; },
			final_ifSlider_ticks()    { return this.final_componentName==="v-slider" ? "always"         : null; },
			final_ifSlider_tickSize() { return this.final_componentName==="v-slider" ? SLIDER_TICK_SIZE : null; },
			final_ifSlider_tickLabels()
			{
				if (!this.final_field || this.final_componentName!=="v-slider") { return null; }
				
				let tickLabels = null;
				
				//If we have predefined items, use their labels
				if (this.final_items_usable.length>0)
				{
					tickLabels = this.final_items_usable.map(loop_item=>loop_item.label);
				}
				//Otherwise, we're prolly using it with min/max vals for a TYPE_INT/TYPE_DECIMAL, so just dump all numbers between the interval, if possible
				else
				{
					const min = this.final_min;
					const max = this.final_max;
					if (min===null || max===null || min>=max)                         { this._throwField(`Can't figure out <v-slider> tick labels because min/max don't make sense: "${min}" vs "${max}"`); }
					if (!B_REST_Utils.number_is(min) || !B_REST_Utils.number_is(max)) { this._throwField(`Expected min/max to be numbers`); } //Only allow strings for D/T
					const step = this.final_step || 1;
					
					tickLabels = [];
					for (let i=min; i<max; i+=step) { tickLabels.push(i); }
					tickLabels.push(max);
				}
				
				//Use them, depending on if we wouldn't be bloating the screen or not
				return tickLabels.length <= SLIDER_MAX_SHOWN_TICK_COUNT ? tickLabels : undefined;
			},
			final_ifSlider_thumbLabel()
			{
				if (this.final_componentName!=="v-slider") { return null; }
				return this.final_field_descriptor?.type_is_int || this.final_field_descriptor?.type_is_decimal ? SLIDER_NUMERIC_ALWAYS_SHOW_THUMB_LABEL : false; //Instead of "always", we could use true, which only displays while dragging
			},
			final_ifTimePicker_useSeconds() { return FieldDescriptor_DB.T_CARE_ABOUT_SECONDS; },
		},
		methods: {
			_throwField(msg) { B_REST_Utils.throwEx(`${this.final_field_quotedName}: ${msg}`); },
			/*
			It's possible that the UI is ready before the data is, so this component is meant to work anyways.
			We can init it multiple ways. Check B_REST_Model::select() docs for more info
				<br-field-db :field="someStandaloneModelField_db" />
				<br-field-db :field="someModel.select('someFieldName')" />
				<br-field-db :field="someModel.select('coords.address')" />
				<br-field-db :field="someModel.select('coords').select('address')" />
				<br-field-db :model="someModel" field="someFieldName" />
				<br-field-db :model="someModel" field="coords.address" />
				<br-field-db :model="someModel" field="lines[3].qty" />
				<br-field-db :model="null"      field="someFieldThatWillSpinForAWhile" />
				<br-field-db :field="null" />
			*/
			async _checkReinit_field()
			{
				if (this.dense) { B_REST_Utils.throwEx(`Not yet supporting dense. Check "dense_notSupported" prop docs`); }
				
				let final_field = null; //The B_REST_ModelField_x we'll use in the end, if possible
				
				if (this.field)
				{
					//Easy way
					if (this.field instanceof B_REST_ModelField_DB)
					{
						if (this.model) { this.final_field=final_field; this._throwField(`Not supposed to pass the B_REST_Model itself, when we already know the field as a B_REST_ModelField_DB instance`); }
						
						final_field = this.field;
					}
					//Else it's already validated as being a string, so we can move on if we know the B_REST_Model in which it resides
					else if (this.model)
					{
						final_field = this.model.select(this.field); //Might throw
						
						//Make sure it didn't point on a field that's not a B_REST_ModelField_DB
						if (!final_field && B_REST_Utils.string_is(this.field)) { this._throwField(`BrModelField got no field / field "${this.field}" not found on that model`); }
						if (!(final_field instanceof B_REST_ModelField_DB)) { this.final_field=final_field; this._throwField(`BrModelField can only work with B_REST_ModelField_DB type fields`); }
					}
					//Else we have a string in field, but we need a model and we don't have one yet
					
					if (final_field)
					{
						//Some validation
						{
							let errMsg = null;
							
							switch (final_field.fieldDescriptor.type)
							{
								case FieldDescriptor_DB.TYPE_ARR:
									if      (this.multiple===false)                                                    { errMsg=`TYPE_ARR fields can't be in :multiple="false" for now`;            } // NOTE: We put multiple prop to null by default. Maybe one day we'll want to force it to :multiple="false" ex because client wants to limit to 1 choice, but then it'd be better to change to TYPE_INT for ex
									else if (!this.picker && !this.items && !final_field.fieldDescriptor.enum_members) { errMsg=`TYPE_ARR fields always need picker, :items or be an enum for now`; } // Has this check at 2 places in code
								break;
								case FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING:
									if (!this.forLang) { this._throwField(`Prop forLang is req for TYPE_MULTILINGUAL_STRING`); }
								break;
								case FieldDescriptor_DB.TYPE_JSON:
									//Leave here for multiple check below
								break;
								default:
									if (this.multiple) { errMsg=`Can only be multiple if TYPE_ARR or TYPE_JSON for now`; }
								break;
							}
							
							if (errMsg)
							{
								this.final_field = final_field;  //NOTE: Hack so throw field can show a debug name
								this._throwField(errMsg);
							}
						}
						
						//NOTE: Code for _picker_x_getModelLabel() used to be here instead of after "this.final_field = final_field". Check below for why
					}
				}
				
				//Set or flush previous pwd related stuff
				this.ifPwd = final_field?.fieldDescriptor.type_is_pwd ? {revealed:false,strengthColor:null,strengthValue:null} : null;
				
				//Then either keep the component not working with a NULL val, or a final ptr to a B_REST_ModelField_DB
				this.final_field = final_field;
				
				//Usually this is a programmer error, ex we've got <br-field-db :model="null" field="firstName">
				if (!this.final_field)
				{
					B_REST_Utils.console_warn(`Initiated a <br-field-db> that has no B_REST_ModelField_DB selected yet; possible that either we've got no :model nor :field prop, or that they're null, for ${this.final_debugFieldNamePath}`);
				}
				
				//IMPORTANT: This must happen after we set this.final_field, otherwise _picker_x_getModelLabel() won't work because final_field_descriptor will be still NULL
				if (this.final_picker_asChips) { await this._picker_multiple_fakeItems_recalc(final_field.val); }
					/*
					NOTE: We used to do the following here, but now it's no longer req, because setting val here will trigger the final_field_val_forWatch() watch to fire and do it:
						await this._checkReinit_picker();
					*/
			},
			/*
			Similar to _checkReinit_field(), so check its docs
			NOTE: We also have B_REST_VueApp_base::pickers_prompt_x() to open fully working standalone pickers that don't need a BrFieldDb instance
			*/
			async _checkReinit_picker()
			{
				if (!this.picker || !this.final_field) { return; }
				
				if (!this.final_field_isLookup && !this.final_multiple) { this._throwField(`Can only use the picker prop on lookup fields or in multiple mode`); }
				
				/*
				For lookups (only in single mode):
					When we select a new val, we can always put the lookup's label in "this.ifPicker_label" via _picker_changeVal(),
					but during boot, if we already had a val before, we'll have to check elsewhere for what val it could have
				*/
				if (!this.final_multiple)
				{
					if (this.final_field.isSet)
					{
						const boundModel = this.final_field.lookup_getModel(); //Can ret NULL if not in cached shared models
						await this._picker_single_updateLabel(boundModel);
					}
					else { await this._picker_single_updateLabel(null); }
				}
			},
				/*
				Emits PICKER_EVENT_SELECT if we select something, yielding {selection} or {selections}, depending on if is multiple
				NOTE: We also have B_REST_VueApp_base::pickers_prompt_x() to open fully working standalone pickers that don't need a BrFieldDb instance
				*/
				async _picker_changeVal(modelOrModelsOrNULL=null)
				{
					this.ifPicker_ignoreFieldValWatch = true; //Check final_field_val_forWatch() docs
					
					let pickerEvent_data = null;
					
					if (modelOrModelsOrNULL===null)
					{
						this.final_field.val             = null;
						this.ifPicker_single_label       = null;
						this.ifPicker_multiple_fakeItems = this.final_multiple ? [] : null;
					}
					else if (this.final_multiple)
					{
						B_REST_Utils.array_isOfClassInstances_assert(B_REST_Model, modelOrModelsOrNULL);
						
						const pks = [];
						for (const loop_model of modelOrModelsOrNULL) { pks.push(loop_model.pk); }
						
						//NOTE: The order of ops is important
						await this._picker_multiple_fakeItems_recalc(pks);
						this.final_field.val = pks;
						
						pickerEvent_data = {selections:modelOrModelsOrNULL};
					}
					else
					{
						B_REST_Utils.instance_isOfClass_assert(B_REST_Model, modelOrModelsOrNULL);
						
						//NOTE: The order of ops is important
						this.final_field.val = modelOrModelsOrNULL.pk;
						await this._picker_single_updateLabel(modelOrModelsOrNULL);
						
						pickerEvent_data = {selection:modelOrModelsOrNULL};
					}
					
					this.ifPicker_ignoreFieldValWatch = false;
					
					this.final_field.userTouch_toggle(true);
					
					//Only emit stuff when we have something, because otherwise we'll get an infinite loop w _on_inputOrChange() calling _picker_changeVal()
					if (pickerEvent_data)
					{
						this._on_inputOrChange(this.final_field.val, this.lazy?"change":"input", /*fixNotEmitting*/true); //async. Check method docs about fixNotEmitting
						
						this._picker_emit(PICKER_EVENT_SELECT,pickerEvent_data); //NOTE: PICKER_EVENT_CANCEL is only when we close selection prompt, and it doesn't flush selection. 
					}
				},
					/*
					Make fake items for final_items(), otherwise selected items won't appear
					We do weird algo to help fetch all labels in 1 single API call. Check B_REST_App_base::_models_toLabelCache_get_throttleAPICall()
					*/
					async _picker_multiple_fakeItems_recalc(pksOrNULL)
					{
						if (pksOrNULL===null) { this.ifPicker_multiple_fakeItems=[]; return; }
						
						const fakeItems = [];
						const promises  = [];
						for (const loop_pk of pksOrNULL)
						{
							fakeItems.push({key:loop_pk,label:PICKER_FAKE_LOADING_LABEL}); //Display a tmp spinning label like "..."
							promises.push(this._picker_x_getModelLabel(loop_pk,/*model*/null));
						}
						
						this.ifPicker_multiple_fakeItems = fakeItems; //IMPORTANT: Put this before the following block, otherwise we won't see any <v-chip> until promises are done
						
						const results = await Promise.all(promises);
						for (let i=0; i<pksOrNULL.length; i++) { fakeItems[i].label=results[i]; }
					},
				/*
				For all @picker:x events, emits as:
					{
						brFieldDb_modelField:           B_REST_ModelField_DB,
						picker:                         B_REST_Vuetify_PickerHandle,
						picker_component:               VueComponent,
						picker_modelList:               B_REST_ModelList|NULL,
						picker_modelList_searchOptions: B_REST_Model_Load_SearchOptions|NULL,
						...extraData
					}
				Simplest usage ex:
					<br-field-db :model="model" field="contact_fk" picker="contactList" />
				Usage ex to show how to override the apiBaseUrl to HC filters in the server to limit ret data & pre-applying filters manually + passing parent PK to child so doing [+] allows transfering proper FK:
					NOTE: For [+] to work properly, should use B_REST_Vuetify_GenericList_GlobalAction::defineCommonAction_add()
					Complex case could be (CPA), we're in a EventForm, we want to choose a FranchiseePark, so open FranchiseeParkList picker, click [+], and transfer a franchisee_fk that has nothing to do w the parent EventForm
					<br-field-db :model="model" field="contact_fk" picker="contactList" :picker-options="pickerOptions" @picker:prompt="setFilters_anotherWay" />
					computed: {
						pickerOptions()
						{
							const parent_pkTag = 123;
							
							return {
								vBind: {
									parent_modelName: "Client",
									parent_pkTag,
									fromLoader: {
										apiBaseUrl:           "/clients/{client}/contacts",
										apiBaseUrl_path_vars: {client:parent_pkTag},
										reloader:             null,
										routeInfo:            null,
									},
								},
								onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
								{
									//One way, affecting filters available in UI. Either use picker_component or picker.component
										picker_component.filters_getByName("name").modelField.val   = "Feust"; //By default a %LIKE%
										picker_component.filters_getByName("date").modelField_x.val = "2023-01-01";
										picker_component.filters_getByName("date").modelField_y.val = "2023-12-31";
										picker_component.filters_getByName("name").hidden = true; //Don't display filter in UI anymore
										//Shortcuts
											picker_component.filters_setVal(  "name", "Feust");
											picker_component.filters_setVal_x("date", "2023-01-01");
											picker_component.filters_setVal_y("date", "2023-12-31");
											picker_component.filters_hide("name");
									
									//Another way, allowing to target any modelField/custom filter the model supports, but NOT affecting filters in UI. Either use picker_modelList_searchOptions or picker_modelList.searchOptions
									//WARNING: If we use this while the BrGenericListBase's UI filters already defined filters w the same names (in mixin), will throw
										picker_modelList_searchOptions.f_pLikeP("name").valOrArr = "Feust";
										picker_modelList_searchOptions.f_between("date").x       = "2023-01-01";
										picker_modelList_searchOptions.f_between("date").y       = "2023-12-31";
									
									//Yet another for when trying to link 2 filters in a BrGenericListBaseFilters (code snippet from a BrGenericListBase mixin:
										B_REST_Vuetify_GenericList.createMixin({
											filters: {
												municipality: {fieldNamePath:"municipality_fk", items:"municipalityList", multiple:true},
												street: {
													fieldNamePath: "municipality_street_fk",
													picker: "municipalityStreetList",
													brFieldDbProps: {
														pickerOptions:
														{
															onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
															{
																//Hack: Travel from the <br-field-db> in the implied BrGenericListBaseFilters back to the BrGenericListBase here,
																//because otherwise, no matter we do onPrompt_setFilters: () => {} or onPrompt_setFilters(){}, "this" doesn't refer to what we want (BrGenericListBase)
																	const this_hack = brFieldDb_component.$parent.$parent.$parent.$parent.$parent.listComponent;
																const municipality_filter = this_hack.modelList.searchOptions.filters_get("municipality_fk", B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN);
																picker_modelList_searchOptions.f_equalOrIN("municipality_fk",false).valOrArr = municipality_filter.finalData;
																	//IMPORTANT: Won't work if the bound BrGenericList has a filter pointing on the same fieldNamePath; the picker's filter val in the UI will overwrite the above
																	//Also, sometimes doesn't work on the 1st shot and we have to close the picker & reopen. Prolly a B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING bug
															},
														},
													},
												},
											},
										}
								},
							};
						},
					},
					methods: {
						setFilters_anotherWay({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions})
						{
							//So we can do the same thing as in onPrompt_setFilters() above here
						},
					},
				*/
				_picker_emit(pickerEventName, extraData={})
				{
					this.$emit(pickerEventName, {...this._picker_destructureForEvents(),...extraData});
					if (pickerEventName===PICKER_EVENT_SELECT||pickerEventName===PICKER_EVENT_CANCEL) { this.ifPicker_handle=null; }
				},
					_picker_destructureForEvents()
					{
						return {
							brFieldDb_component:             this,
							brFieldDb_modelField:            this.final_field,
							picker:                          this.ifPicker_handle,
							picker_component:                this.ifPicker_handle.component, //WARNING: Sometimes (old bREST version ?) breaks on component(NULL).modelList and was because of a B_REST_Vuetify_PickerDef::REUSE_MODE_OFF. Doesn't seem to break in bREST v20+ though...
							picker_modelList:                this.ifPicker_handle.component.modelList                ?? null, //Only if the bound component is a BrGenericListBase der
							picker_modelList_searchOptions:  this.ifPicker_handle.component.modelList?.searchOptions ?? null, //Only if the bound component is a BrGenericListBase der
						};
					},
				//NOTE: We also have B_REST_VueApp_base::pickers_prompt_x() to open fully working standalone pickers that don't need a BrFieldDb instance
				async _picker_single_updateLabel(model=null)
				{
					const pk = this.final_field.val; //Don't take the one of the passed model, because we don't necessarily have it
					
					this.ifPicker_single_label = pk ? await this._picker_x_getModelLabel(pk,model) : null; //NOTE: _picker_x_getModelLabel() works even if model is NULL
				},
				//NOTE: We also have B_REST_VueApp_base::pickers_prompt_x() to open fully working standalone pickers that don't need a BrFieldDb instance
				async _picker_x_getModelLabel(pk, model=null)
				{
					if (!this.final_field_descriptor) { return null; }
					
					const lookupModelName = this.final_field_descriptor.lookup_modelName ?? this.pickerOptions?.lookupModelName ?? null; //NOTE: The pickerOptions.lookupModelName is mainly for TYPE_ARR
					if (!lookupModelName) { this._throwField(`Didn't expect this case to happen, but maybe it's ok. Check if we should do that when lookupModelName is NULL, we use "model.descriptor.name" instead, when filled, or figure model name auto from App.js::pickerDefs.component_ifGenericList_moduleName`); }
					
					let toLabel = null;
					
					if (model)
					{
						const reason = `${this.final_field_quotedName}@picker`; //WARNING: If we want to change this, has impacts in B_REST_Model::toLabel()
						
						toLabel = model.toLabel(reason);
						
						this.$bREST.models_toLabelCache_set(lookupModelName, pk, toLabel); //Put label in picker cache, even if toLabel=NULL. Check its docs
						
						if (toLabel!==null) { return toLabel; } //Note: used to be `${toLabel} - #${pk}`, but was weird to force adding PK and having no way to turn that off
					}
					
					/*
					About the following warning:
						Ex in CPA we're in PresentialEventForm.vue and have a:
							<br-field-db :model="model" field="staff_fk" picker="staffList" />
						Prob is, the default "requiredFields:<all>" isn't enough to load db fields in sub tables, so we need to change it like
								requiredFields:     "<all>|staff.user(firstName|lastName)|staff.<dbOnly>",
							or
								requiredFields:     "<all>|staff.<toLabel>",
					*/
					B_REST_Utils.console_warn(`${this.final_field_quotedName}: Tried to put picked model's label in picker content but got nothing useful; if in a generic form, check that B_REST_Vuetify_GenericFormBase_createMixin's requiredFields prop loads that field. Now reverting to go w models_toLabelCache_get()`);
					
					toLabel = await this.$bREST.models_toLabelCache_get(lookupModelName, pk); //Try to get label from picker cache. Check its docs
					
					return toLabel!==null ? toLabel : `#${pk}`; //If we don't know, at least display its PK
				},
			//Check usages in final_slotConfig_x() computeds
			_final_slotConfig_x(slotName, iconPropName) //slotName: prepend|prepend-inner|append|append-outer, iconPropName ex "icon1"
			{
				if (!!this.$slots[slotName]) { return {useSlot:true}; }
				
				const iconProps = this[iconPropName];
				if (iconProps)
				{
					const parts      = iconProps.split("|");
					const clickEvent = this.$listeners[`click:${iconPropName}`]; //Ex "click:icon3"
					
					return {
						useSlot: false,
						icon:    parts[0],
						color:   parts.length===2 ? parts[1] : null,
						click:   clickEvent || null,
					};
				}
				
				//We get here if we have nothing
				
				/*
				Check if we need a picker btn and we're building the slot we would want it in
				NOTE: Check _picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK
				*/
				if (slotName===DEFAULT_SLOT_CONFIG_PICKER_BTN && this.final_picker_needs) //False if RO / disabled
				{
					B_REST_Utils.console_todo([`When we get handle, should do this.ifPicker_handle.selection=...||B_REST_Vuetify_PickerHandle.NO_SELECTION, but doesn't support receiving PKs yet; only B_REST_Model instances. Check BrGenericListBase::_onSpecsChanged_checkRebuildReloadList() docs`]);
					
					return {
						useSlot: false,
						icon:    "mdi-magnify",
						color:   null,
						click:   async() =>
						{
							//NOTE: Check _picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK
							this.ifPicker_handle = this.$bREST.pickers_getHandle(this.picker, {
								isMultiple: this.final_multiple,
							});
							
							//Set v-bind & v-on we'll have to pass to the BrPicker
							{
								const vBind = this.pickerOptions?.vBind ? {...this.pickerOptions.vBind} : {};
								const vOn   = this.pickerOptions?.vOn   ? {...this.pickerOptions.vOn}   : {};
								
								//Things to do, if we figure out the picker will be for a BrGenericListBase der (most of the time). NOTE: We also have B_REST_VuetifyPickerDef::component_ifGenericList_moduleName
								if (this.ifPicker_handle.def.component_isGenericList)
								{
									//NOTE: Check _picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK
									
									//Make sure it'll have {fromLoader:true} if we don't specify a complex fromLoader or fromModelList, or BrGenericList will throw
									if (!vBind.fromLoader && !vBind.fromModelList) { vBind.fromLoader=true; }
									
									//Unless specified otherwise, by default, have all actions disabled, except for disableGlobalActions, to allow adds (so for now, means that it'll make other unrelated global actions available too D:)
									if (!B_REST_Utils.object_hasPropName(vBind,"disableRowActions")) { vBind.disableRowActions=true; }
									if (!B_REST_Utils.object_hasPropName(vBind,"disableRowClick"))   { vBind.disableRowClick  =true; }
									if (!B_REST_Utils.object_hasPropName(vBind,"disableCellClick"))  { vBind.disableCellClick =true; }
									if (!B_REST_Utils.object_hasPropName(vBind,"disableCellEdits"))  { vBind.disableCellEdits =true; }
								}
								
								this.ifPicker_handle.vBind = vBind;
								this.ifPicker_handle.vOn   = vOn;
							}
							
							/*
							When we have a list w filters right drawer, and that one of the filters opens a picker, the picker will open a v-dialog,
							and if we click anywhere in that v-dialog, the right drawer below would close because it's :temporary in mobile.
							To prevent this, say that as long as the field's picker is opened, we shouldn't close the drawer below by mistake.
							*/
							const rightDrawer = B_REST_Vuetify_RightDrawer.instance;
							const rightDrawer_disableClickOutsideWatch = rightDrawer.visible && rightDrawer.aboveAll;
							if (rightDrawer_disableClickOutsideWatch) { rightDrawer.aboveAll_permanent=true; }
							
							/*
							Prep/reuse a BrPicker, and wait until it's mounted before emitting that we've just opened a picker
							NOTE: Check _picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK
							*/
							const promptPromise = this.ifPicker_handle.prompt();
							await this.ifPicker_handle.component_waitMounted();
							if (this.pickerOptions?.onPrompt_setFilters)
							{
								this.pickerOptions.onPrompt_setFilters({...this._picker_destructureForEvents()});
							}
							this._picker_emit(PICKER_EVENT_PROMPT); //NOTE: We fire this -after- it has opened, so we might want to bind user code to the opened dialog manually. In the event, we can access the B_REST_Vuetify_PickerHandle::component
							
							//Now we can wait for user action
							const selection = await promptPromise; //Yields NULL, a model or arr of 1+ models
							
							if (rightDrawer_disableClickOutsideWatch) { rightDrawer.aboveAll_permanent=false; } //Check var docs for wtf
							
							if (selection) { await this._picker_changeVal(selection); } //Triggers _picker_emit()
							else           { this._picker_emit(PICKER_EVENT_CANCEL);  }
						},
					};
				}
				
				//Check if we need a pwd eye reveal btn and we're building the slot we would want it in
				if (slotName===DEFAULT_SLOT_PWD_EYE_REVEAL_BTN && this.ifPwd)
				{
					return {
						useSlot: false,
						icon:    this.ifPwd.revealed ? "mdi-eye-off" : "mdi-eye",
						color:   null,
						click:   () => this.on_pwdEyeReveal_click(),
					};
				}
				
				return null;
			},
			/*
			Behavior depends on type / component we want to use:
				-Can mean number range
				-Can mean string length range
				-Can mean date/time range (not yet supported)
				-Can mean v-slider range span, either based on the actual passed min/max, or final_items count (not keys related, since there could be gaps and not being numeric)
			*/
			_final_minMax(which)
			{
				if (this[which]!==null) { return this[which]; }
				
				if (!this.final_field) { return null; }
				
				const val = this.final_field[which]!==null ? this.final_field[which] : this.final_field_descriptor[which];
				if (val!==null)
				{
					if (this.final_componentName==="v-slider" && this.final_items_usable.length>0)
					{
						this._throwField(`When we want to display as <v-slider> with items / enum members, we must not pass min/max props`);
					}
					
					switch (this.final_field_descriptor.type)
					{
						case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: case FieldDescriptor_DB.TYPE_D: case FieldDescriptor_DB.TYPE_T:
							this._throwField(`Not yet handling min/max for TYPE_D/T. Has to work when AS_INPUT + AS_DATE/MONTH/TIME_PICKER. Check to merge w tmp "yyyyyy" fix below`);
						break;
						default: return val;
					}
				}
				//For sliders, we just want to know how many items we have and transpose idx with actual keys
				else if (this.final_componentName==="v-slider")
				{
					if (this.final_items_usable.length===0) { this._throwField(`We have a prob because we want to use <v-slider> but we provided no min/max and we don't have items / enum members to base range on`); }
					
					return which==="min" ? 0 : this.final_items_usable.length-1; //NOTE: We don't do Math.min/max(...arr); not keys related, since there could be gaps and not being numeric
				}
				//Prevent JS hell w D & DT where we can enter years like yyyyyy. NOTE: Should merge w the above DT etc throw saying we're not yet handling min/max for TYPE_D/T
				if (which==="max")
				{
					switch (this.final_field_descriptor.type)
					{
						case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: return "9999-12-31 23:59:59";
						case FieldDescriptor_DB.TYPE_D:                                                                              return "9999-12-31";
					}
				}
				
				return null;
			},
			/*
			NOTE: This func isn't called if we have less items than PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT
			Controls which items should appear when we start typing in autocomplete, and which should optionally be prioritized to appear in a user-friendly order.
			Related props:
				autocomplete_acceptSimilarChars:
					For deciding on whether or not to eval similar chars as the same ones, use that prop. Ex "eèéêë" or " -_¯" would all eval to "e" or "-" respectively.
						Note that it applies only to items' {label} and not {flatSearchTags}, because the latter already has all of its accents stripped
				autocomplete_sort:
					For ordering in a user-friendly order, use that prop. Ex if we have items [aaaaaax,bxbbb,ccx,ddd,eee] and we start typing "x",
						false yields [aaaaaaX,bXbbb,ccX] in orig order
						true  yields [bXbbb,ccX,aaaaaaX] so we prioritize what seems to "start with" what we typed
						Then, if we autocomplete_acceptSimilarChars, current logic is:
							say we type "é" and we had ["a é", "e", "f"], it'd output ["a É", "E"] instead of ["E", "a É"], because "é" & "e" match, but "é" should be prioritized over "e"
							-> Check loop_priority_x in code below for if later we want to add more props to control how all works
			IMPORTANT: Reqs defining a ifAutocomplete_filterFunc() that just rets true, or ex entering accents for something w no accents won't find it
			CAVEATS for using B_REST_Utils::string_flatSearchify():
				1) If we search for an item containing "-", " " or some other special chars, since they all get converted to "-", then all items w any of these chars will get matched
				2) If one item has multiple of the above chars in a row like " - ", then they get collapsed into a single "-" so visually things could appear out of order. Ex typing "d":
					                     ↓                                ↓
					"Some - thing - w - dashes"  becomes:  "Some-thing-w-dashes"
					"Some thing w no dashes"     becomes:  "Some-thing-w-no-dashes"
					                 ↑                                      ↑
				-> Note that we added a replacement="-" param so we could set it to "" or etc if it could help
			WARNING:
				If final_items had headers / dividers, these won't show up in the filtered items, so if multiple items labels would appear in diff sub trees, it'd get confusing.
					We could still always show the dividers still having children after filtering, but what would sorting means ?
			*/
			ifAutocomplete_filterAndSort()
			{
				if (!this.ifAutocomplete_typedText_isFilled) { this._throwField("Only use ifAutocomplete_filterAndSort() while autocompleting"); }
				
				const typedText_exact   = this.ifAutocomplete_typedText.toLowerCase();
				const typedText_similar = B_REST_Utils.string_flatSearchify(this.ifAutocomplete_typedText); //NOTE: For phone numbers etc, could set the opt replacement param to ""
				
				const prioritize = (itemText,typedText_x) => { const idx=itemText.indexOf(typedText_x); return idx!==-1?idx:Infinity; }; //Like indexOf, but changes returning of -1 to Infinity
				
				const items = [];
				for (const loop_item of this.final_items)
				{
					if (loop_item.disabled||loop_item.divider||loop_item.header) { continue; }
					
					//NOTE: Check docs for WTF
					const loop_priority_label_exact    =                                        prioritize(loop_item.label.toLowerCase(),                     typedText_exact);
					const loop_priority_label_similar  = this.autocomplete_acceptSimilarChars ? prioritize(B_REST_Utils.string_flatSearchify(loop_item.label),typedText_similar) : Infinity;
					let   loop_priority_flatSearchTags = Infinity;
					if (loop_item.flatSearchTags) //NOTE: Here don't do "&& this.autocomplete_acceptSimilarChars", as that prop should only impact {label}
					{
						//NOTE: Expects these to come from B_REST_Utils::string_flatSearchify() or server's GenUtils::string_flatSearchify()
						for (const loop_flatSearchTag of loop_item.flatSearchTags)
						{
							const loop_priority_flatSearchTag = prioritize(loop_flatSearchTag,typedText_similar);
							if (loop_priority_flatSearchTag<loop_priority_flatSearchTags) { loop_priority_flatSearchTags=loop_priority_flatSearchTag; }
						}
					}
					if (loop_priority_label_exact===Infinity && loop_priority_label_similar===Infinity && loop_priority_flatSearchTags) { continue; }
					
					items.push({
						...loop_item,
						priorities: {label_exact:loop_priority_label_exact, label_similar:loop_priority_label_similar, flatSearchTags:loop_priority_flatSearchTags},
					});
				}
				
				if (!this.autocomplete_sort) { return items; }
				return items.sort((a_item,b_item) =>
				{
					const {label_exact:a_label_exact, label_similar:a_label_similar, flatSearchTags:a_flatSearchTags} = a_item.priorities;
					const {label_exact:b_label_exact, label_similar:b_label_similar, flatSearchTags:b_flatSearchTags} = b_item.priorities;
					
					if (a_label_exact!==b_label_exact) { return a_label_exact-b_label_exact; }
					return Math.min(a_label_similar,a_flatSearchTags) - Math.min(b_label_similar,b_flatSearchTags);
						/*
						NOTE:
							Code used to be just:
								return Math.min(a_label_similar,a_label_exact,a_flatSearchTags) - Math.min(b_label_similar,b_label_exact,b_flatSearchTags);
							... but then if we typed "é" and we had ["a é", "e", "f"], it'd output ["a É", "E"] instead of ["E", "a É"], so seemed not natural
						*/
				});
			},
				//IMPORTANT: Req; check ifAutocomplete_filterAndSort() docs
				ifAutocomplete_filterFunc(loop_item, unused_search, unused_loop_item_text) { return true; },
			on_pwdEyeReveal_click() { this.ifPwd.revealed = !this.ifPwd.revealed; },
			//NOTE: final_field is always set if we an fire this
			async on_clear_click()
			{
				let resultingVal = null;
				
				if (this.final_field_descriptor.type_is_multilingualString)
				{
					this.final_field.clear_inXLang(this.forLang);
					resultingVal = this.final_field.val_get_inXLang(this.forLang);
				}
				else
				{
					this.final_field.clear();
					resultingVal = this.final_field.val;
				}
				
				this._on_inputOrChange(resultingVal, this.lazy?"change":"input", /*fixNotEmitting*/true); //async. Check method docs about fixNotEmitting
			},
			/*
			For input/change events, we also re-emit them as @input & @change, where the param will be the B_REST_ModelField_DB instance itself
			NOTE:
				We don't use v-model anymore, because we can't conditionnally apply .lazy on v-model
				fixNotEmitting:
					For some reason, even if _on_inputOrChange() & $emit() are effectively called,
					the @change or @input is NOT triggered until we later blur the field (not sure if it's a Vuetify bug)
					To make sure, tried adding a $emit('wtf') and @wtf was called right away, and once again when we later blur the field
					At least, stuff happening in _on_inputOrChange() does happen before the blur (tried putting stuff in .br-field-db--inner-component--is-dirty CSS selector)
					But not good because if we want to implement auto save, nothing will happen until we blur the field
						-> So solution was to remove focus from the field itself, but it has to be done w a repaint timer
			*/
			on_input($event)  { this._on_inputOrChange($event,"input");  },
			on_change($event) { this._on_inputOrChange($event,"change"); },
				async _on_inputOrChange($event, which, fixNotEmitting=false)
				{
					if (!this.final_field) { return; }
					
					this.ifAutocomplete_isTypingHack = which==="input";
					
					let newVal = $event;
					
					if (this.picker)
					{
						//If we click the clear btn
						if (newVal===null) { await this._picker_changeVal(null); }
						
						/*
						NOTE:
							We used to have the following branch right here, originating from Flag's revision 277:
								if (!this.final_multiple) { return; } //Otherwise we don't want to do anything more anyways
							This was because when !final_multiple, ex if final_field.val=123, $event will NOT be 123 but the label behind 123, ex "Bob",
								since actual input is being used to display the label in RO, and the val we care about is hidden from UI,
								therefore we didn't want code to fall in:
									else {this.final_field.val=newVal;}
							Now it's no longer needed; just don't do that when single picker 
						*/
					}
					
					//For sliders, we convert slider offset to item keys. Check notes inside the _final_minMax() method
					if (this.final_componentName==="v-slider")
					{
						//Cases to ignore; ex when we recalc range
						if (newVal===null) { return; }
						
						const itemCount = this.final_items_usable.length;
						if (itemCount > 0)
						{
							if (newVal >= itemCount) { this._throwField(`Idx out of range vs nb of usable items`); }
							
							newVal = this.final_items_usable[newVal].key;
						}
					}
					
					if (this.final_field_descriptor.type_is_multilingualString)
					{
						this.final_field.val_set_inXLang(this.forLang, newVal);
					}
					else if (!this.picker||this.final_multiple) { this.final_field.val=newVal; }
					
					if (which==="change") { this.final_field.userTouch_toggle(true); }
					
					if (this.ifPwd)
					{
						if (this.final_field.isEmpty)
						{
							this.ifPwd.strengthColor = null;
							this.ifPwd.strengthValue = null;
						}
						else
						{
							const strengthLvl = this.final_field.validation_pwdStrengthLvl;
							
							this.ifPwd.strengthColor = PWD_STRENGHT_COLORS[strengthLvl];
							this.ifPwd.strengthValue = (strengthLvl+0) * 25;
						}
					}
					
					//Check docs
					if (fixNotEmitting)
					{
						await this.coreMixin_sleep_nextFrame();
						document.activeElement.blur(); //NOTE: Don't replace by something like this.$el.querySelector("input").blur(); doesn't work
					}
					this.$emit(which, this.final_field);
				},
		},
		components: { VTextField, VTextarea, VAutocomplete, VSelect, VRadioGroup, VSlider, VSwitch, VCheckbox, VDatePicker, VTimePicker, VColorPicker },
	};
	
	
	
	
	/*
	For the items prop, it can be an arr like what Vuetify normally expects:
	{
		text:     string | number | object,
		value:    string | number | object,
		disabled: boolean,
		divider:  boolean,
		header:   string
	}
	Or:
		A ModelList, where we'll usually refer to pks and use the toLabel() as text
		A shared list tag like "countryAndSubRegionList". Check B_REST_VueApp_base::sharedLists_x()
	*/
	function validator_items(val)
	{
		if (val===null || val instanceof B_REST_ModelList || B_REST_Utils.string_is(val) || B_REST_Utils.array_is(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "items" to be NULL, an instance of B_REST_ModelList, an arr of objs like {text,value,disabled,divider,header}, or shared list tag`);
		return false;
	}
	function validator_shortLabelUntilBreakpoint(val)
	{
		if (VUETIFY_BREAKPOINTS.includes(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "shortLabelUntilBreakpoint" to be one of "${VUETIFY_BREAKPOINTS.join('", "')}"`);
		return false;
	}
	function validator_as(val)
	{
		if (AS_X_CONSTS.includes(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "as" to be one of "${AS_X_CONSTS.join('", "')}"`);
		return false;
	}
	function validator_minMax(val)
	{
		if (val===null || B_REST_Utils.number_is(val) || B_REST_Utils.string_is(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected min/max to be numbers or string (if D/T)`);
		return false;
	}
	function validator_radioGroupOrientation(val)
	{
		if (RADIO_GROUP_ALL_CONSTS.includes(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "radio-group-orientation" to be one of "${RADIO_GROUP_ALL_CONSTS.join('", "')}`);
		return false;
	}
	
</script>

<style scoped>
	
	.br-field-db {
		position: relative; /* Req for tooltips */
	}
	
		.br-field-db--inner-component {}
			.br-field-db--inner-component--uses-picker {}
				.br-field-db--inner-component--uses-picker :deep(.v-input__slot) {
					pointer-events: none;
				}
				.br-field-db--inner-component--uses-picker :deep(.v-chip--select),
				.br-field-db--inner-component--uses-picker :deep(.v-input__icon--clear), 
				.br-field-db--inner-component--uses-picker :deep(.v-btn) {
					pointer-events: auto;
				}
				.br-field-db--inner-component--uses-picker :deep(.v-input__icon--append) {
					display: none;
				}
			.br-field-db--inner-component--label-lock-icon {
				color: revert; /* Otherwise it's always white */
			}
			.br-field-db--inner-component--is-label-above {}
				.br-field-db--inner-component--is-label-above:not(.v-input--checkbox) {}
					.br-field-db--inner-component--is-label-above:not(.v-input--checkbox) {
						padding-top: calc(1em + 3px); /* Do this, so whether labelAbove is true/false, distance w other elems above remains equal. WARNING: Calc will change if we change the top:-8px below */
					}
					.br-field-db--inner-component--is-label-above:not(.v-input--checkbox) :deep(.v-label) {
						top: -8px; /* WARNING: If we change this val, will need to change the above calc(1em + 3px) */
					}
				.br-field-db--inner-component--is-label-above :deep(.v-input__slot legend) {
					display: none; /* Because otherwise if we want an outline around the field, the near top-left part of the line will be cutted by a transparent <legend> */
				}
				.br-field-db--inner-component--is-label-above :deep(.v-input__append-inner .v-btn) {
					align-items: baseline;
				}
			.br-field-db--inner-component--is-rounded {}
				/* Because otherwise when rounded, progress bar (ex for pwd or loaders) floats out of rounded corners */
				.br-field-db--inner-component--is-rounded :deep(.v-progress-linear) {
					left:  20px;
					right: 20px;
					width: initial;
				}
			.br-field-db--inner-component :deep(.v-progress-linear__background) {
    			display: initial !important;
			}
		
		.br-field-db--inner-component--radio-group {}
			.br-field-db--inner-component--radio-group--label {
				/*
				NOTE:
					Don't remember why had put the following, but if we do this, seems to cause more probs everywhere...
					Was maybe for when it's in a <v-col cols="6"> or smaller...
					
					position:  absolute;
					top:       -18px;
					left:      -4px;
					transform: scale(0.75);
				*/
			}
		
		/* Must optimize */
		.br-field-db-tooltip {}
			/* Do something to position at the bottom of any type of field, of any height */
			.br-field-db-tooltip--positionner-1 {
				position: absolute;
				left:     0;
				right:    0;
				bottom:   0px;
				z-index:  1; /* Req, otherwise other things can get over it in other components, ex other BrFieldDb outlines -_- */
			}
				/* Forcing a height of 0 seems like a req hack, otherwise when tooltip is multi-line, it's gonna get vertically centered above "this" */
				.br-field-db-tooltip--positionner-2 {
					position: relative;
					width:    100%;
					height:   0;
				}
					/* Then since with the last one we have no more height, we need to do this */
					.br-field-db-tooltip--positionner-3 {
						position:   absolute;
						top:        0;
						left:       0;
						right:      0;
						text-align: center;
					}
						/* The actual tooltip */
						.br-field-db-tooltip--style {
							margin:         auto;
							display:        inline-block;
							background:     rgba(97,97,97,0.9);
							color:          #FFFFFF;
							border-radius:  4px;
							font-size:      14px;
							line-height:    22px;
							padding:        5px 16px;
							text-transform: initial;
							pointer-events: none;
						}
		
		/* Req, otherwise we don't see accents when uppercased in :error-messages */
		.br-field-db:deep(.v-messages__message) { line-height:16px; }
		
		/* Otherwise when we put the ".v-text-field--outlined > .v-input__control > .v-input__slot" another color like white, we can see they're not aligned D: */
		.br-field-db :deep(.v-text-field--outlined fieldset) {
			bottom: -1px;
			top:    -1px;
		}
		
		/* DARK THEME STUFF */
			.br-field-db.theme--dark :deep(input)              { caret-color:white !important; }
			.br-field-db.theme--dark :deep(input[type="date"]) { color-scheme:dark;              }
			.br-field-db.theme--dark :deep(input[type="time"]) { color-scheme:dark;              }
		
		/* LIGHT THEME STUFF */
			.br-field-db.theme--light :deep(input)              { caret-color:black !important; }
			.br-field-db.theme--light :deep(input[type="date"]) { color-scheme:light;             }
			.br-field-db.theme--light :deep(input[type="time"]) { color-scheme:light;             }
			.br-field-db.theme--light :deep(.br-field-db--inner-component.v-input--is-focused                                                   label)    { font-weight:500;                  }
			.br-field-db.theme--light :deep(.br-field-db--inner-component:not(.br-field-db--inner-component--is-dirty)      .mdi-checkbox-marked.v-icon)  { color:        black !important; }
			.br-field-db.theme--light :deep(.br-field-db--inner-component:not(.br-field-db--inner-component--is-dirty) .v-item--active          .v-icon)  { color:        black !important; }
			.br-field-db.theme--light :deep(.br-field-db--inner-component:not(.br-field-db--inner-component--is-dirty):not(.error--text)        label)    { color:        black !important; }
			.br-field-db.theme--light :deep(.br-field-db--inner-component:not(.br-field-db--inner-component--is-dirty):not(.error--text)        fieldset) { border-color: black !important; }
		
		/* DIRTY STUFF. Check B_REST_VueApp_base::globalCSSVars docs */
			.br-field-db--inner-component--is-dirty {}
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text)            .v-input__slot:before),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty.v-textarea:not(.error--text) .v-input__slot) {
					border-width: thin  !important;
					border-style: solid !important;
				}
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text)            .v-input__slot:before),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text).v-textarea .v-input__slot),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text)            fieldset) {
					border-color: var(--bREST-BrFieldDb_isDirty_color) !important;
				}
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text).v-label),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text) .v-text-field__slot .v-label),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text) label),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text)      .mdi-checkbox-marked.v-icon),
				.br-field-db :deep(.br-field-db--inner-component--is-dirty:not(.error--text) .v-item--active          .v-icon) {
					color: var(--bREST-BrFieldDb_isDirty_color) !important;
				}
		
</style>