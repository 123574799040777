<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "sessionList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./SessionForm.vue"),
			modelName: "Session",
			fromLoader: {
				apiBaseUrl: "/sessions/",
			},
			cols: {
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				d_priorityReg_from: {
					fieldNamePaths: "d_priorityReg_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent) { return defaultContent.substr(0,10); },
				},
				d_genReg_from: {
					fieldNamePaths: "d_genReg_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent) { return defaultContent.substr(0,10); },
				},
				d_events_from: {
					fieldNamePaths: "d_events_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				d_events_to: {
					fieldNamePaths: "d_events_to",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({icon:"mdi-pencil"}),
					coach_insuranceStuff_generateSessionReport: {
						click: {
							isEnabled(action,model) { return this.$bREST.roles_isAnyRoleAdmin; }, //NOTE: This menu is only for admins for now though...
							async hook(action,selectedModels,isCtrlClickOrMiddleClick) { return this.coach_insuranceStuff_generateSessionReport(selectedModels.pk); },
						},
						icon: "mdi-table-arrow-right"
					}
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				d_events_from: {fieldNamePath:"d_events_from"},
				d_events_to: {fieldNamePath:"d_events_to"},
				d_priorityReg_from: {fieldNamePath:"d_priorityReg_from"},
				d_genReg_from: {fieldNamePath:"d_genReg_from"},
				isAvailable: {fieldNamePath:"isAvailable"},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
		methods: {
			async coach_insuranceStuff_generateSessionReport(session_fk)
			{ 
				const request = new this.$bREST.POST_File("/staffs/coach_insuranceStuff_generateSessionReport/{session_fk}", {session_fk});
				request.expectsContentType_csv();
				return this.$bREST.call_download(request);  //Might throw
			},
		},
	};
	
</script>