
import B_REST_Utils                from "../B_REST_Utils.js";
import B_REST_Model                from "../models/B_REST_Model.js";
import B_REST_ModelFields          from "../models/B_REST_ModelFields.js";
import B_REST_App_base             from "../app/B_REST_App_base.js";
import B_REST_FileControlItem      from "./B_REST_FileControlItem.js";
import B_REST_DOMFilePtr           from "./B_REST_DOMFilePtr.js";
import { B_REST_Request_GET_File } from "../api/B_REST_Request.js";



export default class B_REST_FileControl
{
	//For maxSize & maxSize_perFile
	static get FILE_SIZE_KB() { return B_REST_Utils.FILE_SIZE_KB; }
	static get FILE_SIZE_MB() { return B_REST_Utils.FILE_SIZE_MB; }
	
	//For items_put_validate()
	static get VALIDATION_RESULT_OK()            { return true;          }
	static get VALIDATION_RESULT_READONLY()      { return "ro";          }
	static get VALIDATION_RESULT_COUNT()         { return "count";       }
	static get VALIDATION_RESULT_SIZE_PER_FILE() { return "sizePerFile"; }
	static get VALIDATION_RESULT_SIZE_TOTAL()    { return "sizeTotal";   }
	static get VALIDATION_RESULT_DANGEROUS()     { return "dangerous";   }
	static get VALIDATION_RESULT_MIME()          { return "mime";        }
	
	//For items_toObj()
	static get API_DIRECTIVES_DELETE_SINGLE()   { return "<delete>";    }
	static get API_DIRECTIVES_DELETE_MULTIPLE() { return "<deleteAll>"; }
	
	//For items_download_allZipped()
	static get SECURE_DOWNLOADS_QSA_DISPLAY_NAME_WO_EXT() { return "as"; } //Ex to yield "?as=archive.zip". Must match w server's bREST_base::SECURE_DOWNLOADS_QSA_DISPLAY_NAME_WO_EXT
	
	
	
	//Main config
		_isMultiple        = null;   //If we can only hold 0-1 file, or if it can be 0-N
		_acceptMimePattern = null;   //One of B_REST_Utils.FILES_MIME_PATTERNS_x, custom string, or null for all
		_maxSize           = null;   //In bytes, or NULL. Sum of all files sizes. Can use FILE_SIZE_x helpers
		_maxSize_perFile   = null;   //In bytes, or NULL. For each file. Can use FILE_SIZE_x helpers
		_maxFileCount      = null;   //Must be 1 for single, and null-N for multiple
		_required          = false;  //If we must at least have 1 file
	//Depending on if it's for a model field or a custom file control. One must be set
		_ifModelFiles_modelField                = null;  //Ptr on a B_REST_ModelField_File instance
		_ifCustomFiles_pendingUploadsCustomType = null;  //Tag that must match something in server's bREST_Custom::_abstract_secureData_pendingUploads_getSpecs_forCustomType(), ex "client-docs". WARNING: For now, we can put custom files to pendingUploads but we haven't implemented putting them to a final destination / allowing later edits & downloads. Check server's RouteParser_base::_coreCalls_customFiles_downloads() docs
	//State
		_isReadOnly                    = false;  //Can change
		_zip_apiUrl                    = null;   //Check server's bREST_base::secureData_downloads_makeModelFieldFile_apiUrl() docs. For now only when isModelFiles()
		_zip_isZipping                 = false;
		_items                         = [];     //Arr of B_REST_FileControlItem instances
		_lastValidationResultErrorMsgs = [];     //Arr of errs for last time we went in _items_parseDOMSelectionEvent()
		_unsavedChanges_has            = false;  //Like in B_REST_ModelField_DB
		_userTouch_has                 = false;  //Like in B_REST_ModelField_DB
		_pendingUploadPromises         = [];     //Arr of Promise
	
	
	
	constructor(options)
	{
		B_REST_Utils.object_assert(options);
		
		if (options.ifModelFiles_modelField)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_ModelFields.File, options.ifModelFiles_modelField);
			this._ifModelFiles_modelField = options.ifModelFiles_modelField;
			
			const fieldDescriptor = this._ifModelFiles_modelField.fieldDescriptor;
			this._isMultiple        = fieldDescriptor.isMultiple;
			this._acceptMimePattern = fieldDescriptor.allowedTypes;
			this._maxSize           = null;  //We don't have such info in server's FieldDescriptor_File
			this._maxSize_perFile   = fieldDescriptor.maxFileSize;
			this._maxFileCount      = fieldDescriptor.maxFileCount;
			this._required          = fieldDescriptor.isRequired;
		}
		else if (options.ifCustomFiles_pendingUploadsCustomType)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				isMultiple:        {accept:[Boolean],     required:true},
				acceptMimePattern: {accept:[String,null], default:null},
				maxSize:           {accept:[Number,null], default:null},
				maxSize_perFile:   {accept:[Number,null], default:null},
				maxFileCount:      {accept:[Number,null], default:null},
				required:          {accept:[Boolean],     default:false},
			}, "File control options");
			
			//WARNING: For now, we can put custom files to pendingUploads but we haven't implemented putting them to a final destination / allowing later edits & downloads. Check server's RouteParser_base::_coreCalls_customFiles_downloads() docs
			this._ifCustomFiles_pendingUploadsCustomType = options.ifCustomFiles_pendingUploadsCustomType;
			
			this._isMultiple        = options.isMultiple;
			this._acceptMimePattern = options.allowedTypes;
			this._maxSize           = options.maxSize;
			this._maxSize_perFile   = options.maxFileSize;
			this._maxFileCount      = options.maxFileCount;
			this._required          = options.isRequired;
		}
		else { B_REST_Utils.throwEx(`Expected a ifModelFiles_modelField or ifCustomFiles_pendingUploadsCustomType prop`); }
		
		//In both cases, pimp stuff
		if (!this._isMultiple)
		{
			this._maxFileCount = 1;
			
			//If we specify maxSize, make sure both total + perFile are the same
			if      (this._maxSize)         { this._maxSize_perFile=this._maxSize; }
			else if (this._maxSize_perFile) { this._maxSize=this._maxSize_perFile; }
		}
	};
	//Not an actual destructor; call manually
	destroy() { this.items_destroy(); }
	
	
	
	//ACCESSORS
		get isModelFiles()                     { return this._ifModelFiles_modelField!==null;                        }
		get ifModelFiles_modelField()          { return this._ifModelFiles_modelField;                               }
		get ifModelFiles_isMutable()           { return this._ifModelFiles_modelField?.isMutable            ?? null; }
		get ifModelFiles_isParentModelSaving() { return this._ifModelFiles_modelField?.parentModel_isSaving ?? null; } //NOTE: A field might not have a model (if is a standalone one - maybe less than 1% of the time)
		
		get isCustomFiles()                          { return this._ifCustomFiles_pendingUploadsCustomType!==null; }
		get ifCustomFiles_pendingUploadsCustomType() { return this._ifCustomFiles_pendingUploadsCustomType;        }
		
		get isMultiple()                    { return this._isMultiple;        }
		get acceptMimePattern()             { return this._acceptMimePattern; }
		get maxSize()                       { return this._maxSize;           }
		get maxSize_humanReadable()         { return this._maxSize!==null ? B_REST_Utils.files_humanReadableSize(this._maxSize) : null; }
		get maxSize_perFile()               { return this._maxSize_perFile;   }
		get maxSize_perFile_humanReadable() { return this._maxSize_perFile!==null ? B_REST_Utils.files_humanReadableSize(this._maxSize_perFile) : null; }
		get maxFileCount()                  { return this._maxFileCount;      }
		get required()                      { return this._required;          }
		
		get isReadOnly()    { return this._isReadOnly || this._zip_isZipping || this.ifModelFiles_isMutable===false || this.ifModelFiles_isParentModelSaving; }
		set isReadOnly(val) { this._isReadOnly=val; }
		
		get zip_apiUrl()    { return this._zip_apiUrl;                                                                                       }
		get zip_isZipping() { return this._zip_isZipping;                                                                                    }
		get zip_can()       { return this._isMultiple && this._zip_apiUrl && !this._zip_isZipping && !this.ifModelFiles_isParentModelSaving; }
		
		get hasOngoingAsyncTasks() { return this._zip_isZipping || this.items_ongoingUploads_has; }
	
	
	
	//VALIDATION RELATED
		get validation_isValid() { return this.validation_getErrors(/*onlyOne*/true,/*includeAsyncCustomErrors*/true).length===0; }
		validation_getErrors(onlyOne=false, includeAsyncCustomErrors=true) //NOTE: includeAsyncCustomErrors isn't used for now
		{
			const errors = [];
			
			if (this._lastValidationResultErrorMsgs.length>0)
			{
				if (onlyOne) { return [this._lastValidationResultErrorMsgs[0]]; }
				errors.push(...this._lastValidationResultErrorMsgs);
			}
			
			if (this._required && !this.items_nonDeleted_has)
			{
				const requiredErrorMsg = this._validation_translate("required");
				if (onlyOne) { return [requiredErrorMsg]; }
				errors.push(requiredErrorMsg);
			}
			
			return errors;
		}
			_validation_translate(tag, details={})
			{
				const label = this.ifModelFiles_modelField?.label ?? null;
				details.prefix = label ? `${label}: ` : "";
				
				const locPath = `models.fields.validation.file.${tag}`;
				return B_REST_App_base.instance.t_custom_alt(locPath, locPath, details);
			}
		//Helper ex for usage like in Vue implementation's BrFieldFile
		validation_clearMsgs() { this._lastValidationResultErrorMsgs=[]; }
	
	
	
	//UNSAVED CHANGES & USER TOUCH RELATED
		get unsavedChanges_has() { return this._unsavedChanges_has; }
		unsavedChanges_unflag(options)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				cleanupDeletions: {accept:[Boolean], required:true},
				filesOnly:        {accept:[Boolean], default:false}, //A bit like toObj()
			}, "B_REST_FileControl::unsavedChanges_unflag()");
			
			this._unsavedChanges_has = false;
			
			if (options.cleanupDeletions) { this.items_destroy_ifStored_toDelete(); }
		}
		unsavedChanges_flag() { this._unsavedChanges_has=true; }
		//NOTE: Not used in Vue implementation's BrFieldFile anymore; check its func w the same name
			get userTouch_has() { return this._userTouch_has; }
			userTouch_toggle(touched)
			{
				this._userTouch_has = touched;
				this.validation_clearMsgs();
			}
	
	
	
	//ITEMS RELATED
		get items()                       { return this._items;                                                                                                 }
		get items_count()                 { return this._items.length;                                                                                          } //Includes items to delete
		get items_has()                   { return this._items.length>0;                                                                                        } //Includes items to delete
		get items_nonDeleted_has()        { return !!this._items.find(loop_item => !loop_item.ifStored_toDelete);                                               }
		get items_size()                  { return this._items.reduce((acc,loop_item)=>acc+loop_item.fileInfo.size,0);                                          } //Note that some might yield a size of NULL
		get items_size_humanReadable()    { return B_REST_Utils.files_humanReadableSize(this.items_size);                                                       }
		get items_canPutMore()            { return !this.isReadOnly && (!this._isMultiple || this._maxFileCount===null || this.items_count<this._maxFileCount); } //NOTE: Doesn't take maxSize into account
		get items_hasUnsavedChanges()     { return !!this._items.find(loop_item => !loop_item.status_isStored || loop_item.ifStored_toDelete);                  } //Includes ones with errs too
		get items_ongoingUploads()        { return this._items.filter(loop_item => loop_item.status_isNewPreparing);                                            }
		get items_ongoingUploads_has()    { return this.items_ongoingUploads.length>0;                                                                          }
		get items_isNewPreparing()        { return this._items.filter(loop_item => loop_item.status_isNewPreparing);                                            }
		get items_isNewPreparing_has()    { return this.items_isNewPreparing.length>0;                                                                          }
		get items_failedUploads()         { return this._items.filter(loop_item => loop_item.status_isNewPreparingFailed);                                      }
		get items_failedUploads_has()     { return this.items_failedUploads.length>0;                                                                           }
		get items_status_isStored()       { return this._items.filter(loop_item => loop_item.status_isStored);                                                  }
		get items_status_isStored_has()   { return this.items_status_isStored.length>0;                                                                         }
		get items_ifStored_toDelete()     { return this._items.filter(loop_item => loop_item.ifStored_toDelete);                                                }
		get items_ifStored_toDelete_has() { return this.items_ifStored_toDelete.length>0;                                                                       }
		
		items_get_byIdx(idx)
		{
			if (idx >= this.items_count) { B_REST_Utils.throwEx(`Idx ${idx} out of bounds (${this.items_count})`); }
			return this._items[idx];
		}
		items_get_byFrontendUUID(frontendUUID)
		{
			const item = this._items.find(loop_item => loop_item.frontendUUID===frontendUUID);
			if (!item) { B_REST_Utils.throwEx(`UUID ${frontendUUID} not found`); }
			
			return item;
		}
		//Careful: not to confuse with items_destroy_one(); here, we either destroy it or flag ifStored_toDelete + optionally save changes
		items_toggle_remove(item)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_FileControlItem, item);
			
			if (item.status_isStored)
			{
				//Flag to del, if not already done so
				if (!item.ifStored_toDelete) { item.ifStored_toDelete = true; }
			}
			else
			{
				this.items_destroy_one(item);
				this._lastValidationResultErrorMsgs = [];
			}
			
			this.unsavedChanges_flag();
		}
		/*
		Removes all items from the control, also releasing objectURL buffers, if any
		To only -flag- item as to del later, use items_toggle_remove() instead
		*/
		items_destroy()
		{
			//Del all imgs tmp ObjectURL and stuff, if any
			for (const loop_item of this._items) { loop_item.ifNewPreparing_releaseMemory(); }
			
			this._items = [];
		}
			//Variant targetting only those that are isNewPreparing (and releasing their memory)
			items_destroy_isNewPreparing()
			{
				for (const loop_item of this.items_isNewPreparing) { this.items_destroy_one(loop_item); }
			}
			//Variant targetting only those that are ifStored_toDelete
			items_destroy_ifStored_toDelete()
			{
				for (const loop_item of this.items_ifStored_toDelete) { this.items_destroy_one(loop_item); }
			}
			//Simply removes the item from the arr, not calling any API nor flagging as ifStored_toDelete=true
			items_destroy_one(item)
			{
				B_REST_Utils.array_remove_byVal(this._items, item);
				item.ifNewPreparing_releaseMemory();
			}
		//Check B_REST_API::call_download() docs for params & what it returns
		async items_download_allZipped(baseNameWExt=null, domContainer=null)
		{
			if (!this.zip_can) { B_REST_Utils.throwEx(`savedModel_zip_apiUrl must be defined in multiple mode, and we must have no ongoing tasks`); }
			this._zip_isZipping = true;
			
			try
			{
				const request = new B_REST_Request_GET_File(this._zip_apiUrl);
				request.needsAccessToken = true; //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT. For now, always assume we can only do that when logged
				request.qsa_add(B_REST_FileControl.SECURE_DOWNLOADS_QSA_DISPLAY_NAME_WO_EXT, ""); //NOTE: Could be something like "Archive", "Files"... But maybe better to leave server handle that
				const { baseNameWExt:final_baseNameWExt, response } = await B_REST_App_base.instance.call_download(request, baseNameWExt, domContainer);
				
				this._zip_isZipping = false;
				
				return { baseNameWExt:final_baseNameWExt, response };
			}
			catch (e)
			{
				this._zip_isZipping = false;
				throw e;
			}
		}
		/*
		Against options, checks if it'd be ok to set / add the specified B_REST_DOMFilePtr instance
		Rets one of VALIDATION_RESULT_x, or throws if we don't receive an instance of B_REST_DOMFilePtr
		*/
		items_validateDOMFilePtr(domFilePtr)
		{
			return this._items_validateDOMFilePtrArr([domFilePtr])[0];
		}
			//NOTE: Throws if we don't receive instances of B_REST_DOMFilePtr
			_items_validateDOMFilePtrArr(domFilePtrArr)
			{
				//First validate that all is of the right type, and that we've got something
				B_REST_Utils.array_isOfClassInstances_assert(B_REST_DOMFilePtr, domFilePtrArr);
				if (domFilePtrArr.length===0) { B_REST_Utils.throwEx(`Got an empty B_REST_DOMFilePtr instances arr`); }
				
				let currentCount = this.items_count;
				let currentSize  = this._isMultiple ? this.items_size : 0;
				
				return domFilePtrArr.map(loop_domFilePtr =>
				{
					if (this.isReadOnly) { return B_REST_FileControl.VALIDATION_RESULT_READONLY; }
					
					//For counts, always allow when we're in single mode, as we must be able to replace the previous one, without having to bother removing it first
					if (this._isMultiple && this._maxFileCount && currentCount>=this._maxFileCount) { return B_REST_FileControl.VALIDATION_RESULT_COUNT; }
					
					//NOTE: Do this to avoid calculating file's size for no reason (mem intensive for non blobs)
					let loop_fileSize = 0;
					if (this._maxSize_perFile || this._maxSize)
					{
						loop_fileSize = loop_domFilePtr.size;
						
						if (this._maxSize_perFile && loop_fileSize             > this._maxSize_perFile) { return B_REST_FileControl.VALIDATION_RESULT_SIZE_PER_FILE; }
						if (this._maxSize         && currentSize+loop_fileSize > this._maxSize)         { return B_REST_FileControl.VALIDATION_RESULT_SIZE_TOTAL;    }
					}
					
					//Mime (or ext) validation
					{
						let loop_mimeOrExt = loop_domFilePtr.mime_from_bestGuess; //Can ret NULL
						if (!loop_mimeOrExt) { loop_mimeOrExt=loop_domFilePtr.ext; }
						
						if (loop_mimeOrExt && B_REST_Utils.files_mime_isDangerous(loop_mimeOrExt)) { return B_REST_FileControl.VALIDATION_RESULT_DANGEROUS; } //Would throw if mime is empty
						
						if (this._acceptMimePattern)
						{
							if (!loop_mimeOrExt || !B_REST_Utils.files_mime_matchesPattern(loop_mimeOrExt,this._acceptMimePattern)) { return B_REST_FileControl.VALIDATION_RESULT_MIME; } //Would throw if mime is empty
						}
					}
					
					//If it's ok to add it, then "pretend" we're adding it, to correctly validate the other files
					currentCount++;
					currentSize += loop_fileSize; //NOTE: This will +=0 if we didn't care about size restrictions
					
					return B_REST_FileControl.VALIDATION_RESULT_OK;
				});
			}
		/*
		Intended to take a B_REST_DOMFilePtr (ex File input, drag n drop...) and send it to the pendingAPIUploads dir to get a hash, before actually using (saving) it in a future API call
		Validates first if it's ok to set/add, yielding a const of VALIDATION_RESULT_x, then upload if it's ok to do so
		Throws if we don't receive a B_REST_DOMFilePtr instance
		If we want to know when it's done sending, use items_waitOngoingUploads()
		*/
		items_prepare(domFilePtr)
		{
			const validationResult = this._items_validateDOMFilePtrArr([domFilePtr])[0]; //Throws if it wasn't a B_REST_DOMFilePtr instance
			
			if (validationResult===B_REST_FileControl.VALIDATION_RESULT_OK)
			{
				//In single mode, first start by removing any previous item, if any. Will work, even if the previous one was being referred in an ongoing async task
				if (!this._isMultiple && this.items_has) { this.items_destroy(); }
				
				this._items_prepare_factorizeDOMFilePtrList_reStartTransfer([domFilePtr]);
			}
			
			return validationResult;
		}
		/*
		Starts uploading all specified B_REST_DOMFilePtr instances in a single API call (not in parallel)
		We can choose to not send anything at all if any file doesn't pass validation specs, with ignoreInvalids=false
		Rets an arr of validation results. Upload promise is NULL if no upload will be performed
		Throws if we don't receive an arr of B_REST_DOMFilePtr instances, or an empty one
		If we want to know when they're done sending, use items_waitOngoingUploads()
		*/
		items_prepare_grouped(domFilePtrArr, ignoreInvalids)
		{
			const validationResultArr = this._items_validateDOMFilePtrArr(domFilePtrArr); //Throws if it wasn't an arr of B_REST_DOMFilePtr instances / empty
			const domFilePtrArr_valid = validationResultArr.filter(loop_validationResult => loop_validationResult===B_REST_FileControl.VALIDATION_RESULT_OK);
			
			if (domFilePtrArr_valid.length>0 && (ignoreInvalids || domFilePtrArr.length===domFilePtrArr_valid.length))
			{
				this._items_prepare_factorizeDOMFilePtrList_reStartTransfer(domFilePtrArr_valid);
			}
			
			return validationResultArr;
		}
		//If we want to know when it's done sending, use items_waitOngoingUploads()
		items_prepare_failedUploads_retry(item=null)
		{
			if (item)
			{
				if (!item.status_isNewPreparingFailed) { B_REST_Utils.throwEx(`Item must be in STATUS_NEW_PREPARING_FAILED`); }
				this._items_prepare_reStartTransfer([item]);
			}
			else if (!this.items_failedUploads_has) { B_REST_Utils.throwEx(`Got no failed uploads`); }
			else { this._items_prepare_reStartTransfer(this.items_failedUploads); }
		}
			//If we received multiple items, we'll group them together in a single call
			async _items_prepare_reStartTransfer(currentItems)
			{
				if (currentItems.length===0) { B_REST_Utils.throwEx(`Got no items to (re)transfer`); } //Should never happen though
				
				this.unsavedChanges_flag();
				
				let uploadPromise_s = null;
				let uploadPromise_f = null;
				const promise = new Promise((s,f) =>
				{
					uploadPromise_s = s;
					uploadPromise_f = f;
				});
				this._pendingUploadPromises.push(promise);
				
				try
				{
					for (const loop_item of currentItems) { loop_item.setStatus_preparing(); }
					
					//Setup a callback that *might* get fired multiple times while uploading
					const uploadProgressCallback = (totalBytes,transferredBytes,percent) =>
					{
						for (const loop_item of currentItems) { loop_item.ifNewPreparing_progression=percent; }
					};
					
					const isSingle         = currentItems.length===1;
					const domFilePtrOrList = isSingle ? currentItems[0].ifNewX_domFilePtr : currentItems.map(loop_item => loop_item.ifNewX_domFilePtr);
					let   response         = null; //Instance of B_REST_Response
					
					if (this._ifModelFiles_modelField)
					{
						const modelName  = this._ifModelFiles_modelField.fieldDescriptor.descriptor.name;
						const fieldName  = this._ifModelFiles_modelField.fieldDescriptor.name;
						const methodName = isSingle ? "pendingUploads_modelFiles_single" : "pendingUploads_modelFiles_multiple";
						
						response = await B_REST_App_base.instance[methodName](modelName,fieldName, domFilePtrOrList, uploadProgressCallback);
					}
					else if (this._ifCustomFiles_pendingUploadsCustomType)
					{
						const methodName = isSingle ? "pendingUploads_customFiles_single" : "pendingUploads_customFiles_multiple";
						
						response = await B_REST_App_base.instance[methodName](this._ifCustomFiles_pendingUploadsCustomType, domFilePtrOrList, uploadProgressCallback);
					}
					else { B_REST_Utils.throwEx(`Expected a ifModelFiles_modelField or ifCustomFiles_pendingUploadsCustomType prop`); }
					
					const hashList = isSingle ? (response.data.file?[response.data.file]:[]) : (response.data.files?response.data.files:[]); //Arr of stuff like "f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf"
					
					//Make sure we get the right nb of hashes
					if (currentItems.length!==hashList.length) { B_REST_Utils.throwEx(`Expected call to return ${currentItems.length} hashes; got ${hashList.length}`); }
					
					//Assign pending upload hashes
					currentItems.forEach((loop_item,loop_idx) => loop_item.setStatus_prepared(hashList[loop_idx]));
					
					uploadPromise_s();
				}
				catch (e) //Will contain either an Error or B_REST_Response instance
				{
					for (const loop_item of currentItems) { loop_item.setStatus_preparingFailed(); }
					
					uploadPromise_f();
				}
				
				B_REST_Utils.array_remove_byVal(this._pendingUploadPromises, promise);
			}
			//Converts B_REST_DOMFilePtr into B_REST_FileControlItem instances
			_items_prepare_factorizeDOMFilePtrList_reStartTransfer(domFilePtrArr)
			{
				const items = [];
				
				for (const loop_domFilePtr of domFilePtrArr)
				{
					const loop_item = B_REST_FileControlItem.factory_fromDOMFilePtr(loop_domFilePtr);
					loop_item._control = this;
					loop_item.setStatus_preparing(null);
					
					items.push(loop_item);
					this._items.push(loop_item);
				}
				
				this._items_prepare_reStartTransfer(items);
			}
		/*
		Resolves when all STATUS_NEW_PREPARING flipped to either:
			STATUS_NEW_PREPARING_FAILED
			STATUS_NEW_PREPARED
		Does multiple passes, in case some gets added while we're already waiting
		Depending on dieOnFailedTransfers, will break if any item (even for previous transfers) remains in STATUS_NEW_PREPARING_FAILED
		*/
		async items_waitOngoingUploads(dieOnFailedTransfers)
		{
			while (this._pendingUploadPromises.length>0) { await Promise.allSettled(this._pendingUploadPromises); }
			
			//If we want to die when any item remains in a failed state
			if (dieOnFailedTransfers && this.items_failedUploads_has) { B_REST_Utils.throwEx(`Got a failed transfer and we want to die on failed transfers`); }
		}
		/*
		API calls expect to receive a model like such, so send adds & dels directives in diff ways, depending on if it's a single / multiple files field
			{
				"logo": "<delete>",
				"logo": {pendingAPIUpload_baseNameWExt_hashed:"f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf", _apiUID_:123},
				"pics": "<deleteAll>",
				"pics": {
					"add: [
						{pendingAPIUpload_baseNameWExt_hashed:"f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf",     _apiUID_:123},
						{pendingAPIUpload_baseNameWExt_hashed:"9a8sd0g8as09d9g80as8d09g80d8a0gds980ags9-bob-new.pdf", _apiUID_:456}
					],
					"delete": ["bob.pdf", "bob_1.docx"]
				}
			}
		This return like the above
		We care about those in status:
			STATUS_NEW_PREPARED               -> Adds
			STATUS_STORED + ifStored_toDelete -> Dels
		All others will be ignored; we don't wait for uploads
		NOTE: "<deleteAll>" can only happen in isMultiple mode
		*/
		items_toObj(options_notUsed)
		{
			if (this._isMultiple)
			{
				const adds = [];
				const dels = [];
				
				for (const loop_item of this._items)
				{
					switch (loop_item.status)
					{
						case B_REST_FileControlItem.STATUS_NEW_PREPARING: case B_REST_FileControlItem.STATUS_NEW_PREPARING_FAILED:
							//Skip cases
						break;
						case B_REST_FileControlItem.STATUS_NEW_PREPARED:
							adds.push(loop_item.ifNewPrepared_toApiFileObj());
						break;
						case B_REST_FileControlItem.STATUS_STORED:
							if (loop_item.ifStored_toDelete) { dels.push(loop_item.fileInfo.baseNameWExt); }
						break;
						default:
							B_REST_Utils.throwEx(`Got unexpected status "${loop_item.status}"`);
						break;
					}
				}
				
				const isDeletingAllItems = dels.length>0 && dels.length===this.items_count;
				
				if (adds.length===0)
				{
					//If we've got nothing to do
					if (dels.length===0) { return null; }
					
					//If we want to del all afterall, just use the easy directive
					if (isDeletingAllItems) { return B_REST_FileControl.API_DIRECTIVES_DELETE_MULTIPLE; }
				}
				
				//Else ret some adds and some dels
				return {add:adds, delete:dels};
			}
			else
			{
				if (this._items.length>1) { B_REST_Utils.throwEx(`Not supposed to have more than 1 item in single mode`); }
				
				//If we've got no items at all, it means we never added anything in the past either. Otherwise, wanting to remove would become ifStored_toDelete=true
				if (this._items.length===0) { return null; }
				
				const item = this._items[0];
				
				switch (item.status)
				{
					case B_REST_FileControlItem.STATUS_NEW_PREPARING: case B_REST_FileControlItem.STATUS_NEW_PREPARING_FAILED:
						//Skip cases
					break;
					case B_REST_FileControlItem.STATUS_NEW_PREPARED:
						return item.ifNewPrepared_toApiFileObj();
					break;
					case B_REST_FileControlItem.STATUS_STORED:
						if (item.ifStored_toDelete) { return B_REST_FileControl.API_DIRECTIVES_DELETE_SINGLE; }
					break;
					default:
						B_REST_Utils.throwEx(`Got unexpected status "${item.status}"`);
					break;
				}
				
				//If nothing changed, or we got an upload that's still pending/failed at this time
				return null;
			}
		}
		/*
		Call this when loading an existing instance, and each time we save
		API calls can return models like such, so expect to receive either a {...} or [{...}], depending on if it's a single / multiple files field
			{
				"logo": {baseNameWExt:"logo.bmp", baseNameWOExt:"logo", ext:"bmp", size:<bytes>,mime:"image/bmp",width:null,height:null, apiUrl:<apiUrl>, resizedVersion:{size,width,height,apiUrl:<apiUrl>}, _apiUID_:123},
				"docs": {
					zip_apiUrl: null | <apiUrlZip>, //NOTE: Null if list is empty
					list: [
						{baseNameWExt:"stuff.pdf", baseNameWOExt:"stuff", ext:"pdf", size:<bytes>,mime:"application/pdf",width:null,height:null, apiUrl:<apiUrl>, resizedVersion:null, _apiUID_:123},
						{baseNameWExt:"logo.bmp", baseNameWOExt:"logo", ext:"bmp", size:<bytes>,mime:"image/bmp",width:null,height:null, apiUrl:<apiUrl>, resizedVersion:{size,width,height,apiUrl:<apiUrlSmall>}, _apiUID_:456},
					}
				}
			}
			-> Check server's bREST_base::secureData_downloads_makeModelFieldFile_apiUrl() docs for <apiUrl>
		Checks if some items don't exist in the item list yet and adds them
		For STATUS_NEW_PREPARED, compares them by frontendUUID to assign final fileInfo and flip it to STATUS_STORED
		Doesn't cleanup items in the following status. Consider using items_destroy_one() or items_destroy()
			STATUS_NEW_PREPARING
			STATUS_NEW_PREPARING_FAILED
			STATUS_STORED + ifStored_toDelete
		In multiple mode, also sets dir zip apiUrl, if available
		*/
		items_fromObj(obj)
		{
			B_REST_Utils.object_assert(obj);
			
			if (this._isMultiple)
			{
				if (!B_REST_Utils.object_hasPropName(obj,"list")) { B_REST_Utils.throwEx(`Expected to receive a list:[]`); }
				
				for (const loop_obj of obj.list) { this._items_fromObj_parse(loop_obj); }
				
				//Check if we can get a zip url
				if (B_REST_Utils.object_hasPropName(obj,"zip_apiUrl")) { this._zip_apiUrl = obj.zip_apiUrl; }
			}
			else
			{
				if (!B_REST_Utils.object_hasPropName(obj,"baseNameWExt")) { B_REST_Utils.throwEx(`Expected to receive an obj`); }
				
				this._items_fromObj_parse(obj);
			}
		}
			_items_fromObj_parse(obj)
			{
				let item = null;
				
				//Maybe it's an add we've just done and we got it back
				const frontendUUID = obj[B_REST_Model.API_UID_FIELDNAME];
				if (frontendUUID)
				{
					item = this.items_get_byFrontendUUID(frontendUUID);
					if (!item) { B_REST_Utils.throwEx(`Couldn't find back item with frontendUUID "${frontendUUID}"`); }
					
					item.setStatus_stored(obj);
				}
				//Else it's a file we already knew to be on the server, or that it was added from somewhere else and we don't know about it
				else
				{
					//First check if we already have it
					item = this._items.find(loop_item => loop_item.status_isStored && loop_item.fileInfo.baseNameWExt===obj.baseNameWExt);
					if (!item)
					{
						item = B_REST_FileControlItem.factory_fromAPIFieldFileData(obj);
						item._control = this;
						this._items.push(item);
					}
				}
			}
		/*
		Event for when we change files in an <input type="file">
		Does lots of async stuff, but doesn't wait for them to finish
		Rets if we got valid files
		WARNING:
			Don't forget to do this after the call, if we want to react to next changes:
				domInputFile.value = null;
		*/
		items_parseDOMSelectionEvent_onInputFileChange(domInputFile, options={})
		{
			const domFilePtrOrArrOrNULL = B_REST_DOMFilePtr.from_fileInput(domInputFile);
			return this._items_parseDOMSelectionEvent(domFilePtrOrArrOrNULL,options);
		}
		/*
		Event for when we drop anything (even buttons) over something. Ignores if we got no files in the end
		Does lots of async stuff, but doesn't wait for them to finish
		Rets if we got valid files
		*/
		items_parseDOMSelectionEvent_onDrop(dropEvent, options={})
		{
			const domFilePtrOrArrOrNULL = B_REST_DOMFilePtr.from_dropEvent(dropEvent, this._isMultiple);
			return this._items_parseDOMSelectionEvent(domFilePtrOrArrOrNULL,options);
		}
			/*
			Takes B_REST_DOMFilePtr instance(s) and adds / replace items in the control
			Also takes care of updating an existing model, if we don't want to wait to save things manually later
			Does lots of async stuff, but doesn't wait for them to finish
			Rets if we got valid files
			*/
			_items_parseDOMSelectionEvent(domFilePtrOrArrOrNULL, options={})
			{
				if (this.isReadOnly) { B_REST_Utils.throwEx(`Can't do that when isReadOnly`); }
				
				if (domFilePtrOrArrOrNULL===null) { return false; }
				
				options = B_REST_Utils.object_hasValidStruct_assert(options, {
					uploadMultipleGrouped:        {accept:[Boolean],     default:false},
					groupedUploadsIgnoreInvalids: {accept:[Boolean],     default:false},
					injectFilePrefix:             {accept:[String,null], default:null}, //NOTE: If requirements become too custom, change this into a callback to run on each file
				}, "B_REST_FileControl::_items_parseDOMSelectionEvent()");
				
				//Uniformize to always work with arrs
				const domFilePtrArr = B_REST_Utils.array_is(domFilePtrOrArrOrNULL) ? domFilePtrOrArrOrNULL : [domFilePtrOrArrOrNULL];
				if (options.injectFilePrefix!==null)
				{
					for (const loop_domFilePtr of domFilePtrArr) { loop_domFilePtr.baseNameWExt = `${options.injectFilePrefix}${loop_domFilePtr.baseNameWExt}`; }
				}
				
				let validationResultArr = null; //Arr of validationResult from items_prepare_grouped() or items_prepare()
				
				if (this._isMultiple)
				{
					if (options.uploadMultipleGrouped) { validationResultArr = this.items_prepare_grouped(domFilePtrArr,options.groupedUploadsIgnoreInvalids); }
					else                               { validationResultArr = domFilePtrArr.map(loop_domFilePtr => this.items_prepare(loop_domFilePtr));      }
				}
				else
				{
					//NOTE: domFilePtrArr.length should be 1, otherwise we'll discard extra ones
					
					const validationResult = this.items_prepare(domFilePtrArr[0]);
					validationResultArr = [validationResult];
				}
				
				//Check if we've got any err to report
				const errorMsgs = [];
				let addedFiles  = false;
				validationResultArr.forEach((loop_validationResult,loop_idx) =>
				{
					if (loop_validationResult===B_REST_FileControl.VALIDATION_RESULT_OK) { addedFiles=true; }
					else
					{
						if (!options.groupedUploadsIgnoreInvalids) { addedFiles=false; }
						
						const loop_domFilePtr = domFilePtrArr[loop_idx];
						let   loop_errorMsg   = null;
						
						switch (loop_validationResult)
						{
							case B_REST_FileControl.VALIDATION_RESULT_READONLY:
								loop_errorMsg = this._validation_translate("readonly");
							break;
							case B_REST_FileControl.VALIDATION_RESULT_COUNT:
								loop_errorMsg = this._validation_translate("count", {maxFileCount:this._maxFileCount});
							break;
							case B_REST_FileControl.VALIDATION_RESULT_SIZE_PER_FILE:
								loop_errorMsg = this._validation_translate("sizePerFile", {itemSize:loop_domFilePtr.size_humanReadable,maxSize:this.maxSize_perFile_humanReadable});
							break;
							case B_REST_FileControl.VALIDATION_RESULT_SIZE_TOTAL:
								loop_errorMsg = this._validation_translate("sizeTotal", {currentSize:this.items_size_humanReadable,itemSize:loop_domFilePtr.size_humanReadable,maxSize:this.maxSize_humanReadable});
							break;
							case B_REST_FileControl.VALIDATION_RESULT_DANGEROUS:
								loop_errorMsg = this._validation_translate("dangerous");
							break;
							case B_REST_FileControl.VALIDATION_RESULT_MIME:
								loop_errorMsg = this._validation_translate("mime"); //We could display this._acceptMimePattern, but it's not really user friendly
							break;
							default:
								B_REST_Utils.throwEx(`Unexpected validation result "${loop_validationResult}"`);
							break;
						}
						
						errorMsgs.push(`${loop_domFilePtr.baseNameWExt}: ${loop_errorMsg}`);
					}
				});
				
				this._lastValidationResultErrorMsgs = errorMsgs;
				
				return addedFiles;
			}
};
